import React from 'react'
import cx from 'classnames'

interface IDividerProps {
	className?: string
}

const Divider: React.FC<IDividerProps> = ({ className }) => {
	const classes = cx(`h-[1px] w-full bg-light2 my-8`, className)

	return <div className={classes}></div>
}

export default Divider
