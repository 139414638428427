import React, { useEffect, useState } from 'react'

import diamondIcon from '@/images/video-diamond.svg'
import { CONFIG_COLORS } from '@/types'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import Blade from '../Blade'
import Button, { BTN_COLOR } from '../Button'
import Heading from '../Heading/Heading'
import Link from '../Link'
import MediaItem from '../MediaItem'
import SVG from '../SVG'
import DesktopRosterNavigation from './components/DesktopRosterNavigation'
import MobileRosterNavigation from './components/MobileRosterNavigation'
import MobileThumbnailCarousel from './components/MobileThumbnailCarousel'
import './style.scss'
import { useInView } from 'react-intersection-observer'

export interface IBoss {
	description: string
	featuredImage: {
		gatsbyImageData: IGatsbyImageData
		format: string
		isImage: boolean
		url: string
	}
	name: string
}

interface IBossCarouselBladeProps {
	bosses: IBoss[]
}

const BossCarouselBlade: React.FC<IBossCarouselBladeProps> = ({ ...props }) => {
	const { bosses, header, subHeader, description } = props
	const [activeBossIndex, setActiveBossIndex] = useState<number>(0)
	const [activeBossOverride, setActiveBossOverride] = useState<boolean>(false)
	const { ref, inView, entry } = useInView({
		/* Optional options */
		threshold: 0.3,
		triggerOnce: true,
	})
	const handleActiveCharacterIndex = (direction: string) => () => {
		setActiveBossOverride(true)

		if (direction === `decrease`) {
			if (activeBossIndex === 0) {
				setActiveBossIndex(bosses.length - 1)
				return
			}
			setActiveBossIndex(activeBossIndex - 1)
		}
		if (direction === `increase`) {
			if (activeBossIndex === bosses.length - 1) {
				setActiveBossIndex(0)
				return
			}
			setActiveBossIndex(activeBossIndex + 1)
		}
	}

	useEffect(() => {
		if (activeBossOverride) {
			return
		}
		const interval = setInterval(() => {
			if (activeBossIndex === bosses.length - 1) {
				setActiveBossIndex(0)
				return
			}
			setActiveBossIndex(activeBossIndex + 1)
		}, 5000)

		return () => clearInterval(interval)
	}, [activeBossIndex, activeBossOverride, bosses.length])

	return (
		<section className="relative" ref={ref}>
			<div className="w-full h-full top-0 left-0 right-0 roster-gradient absolute z-10 overflow-hidden ">
				<div className="w-full h-full bg-black2"></div>
			</div>
			<div className="absolute top-0 right-0 z-30 w-full h-full clipped-roster-blade"></div>
			<Blade className="relative lg:min-h-[600px] xl:min-h-[800px] w-screen pb-0 lg:flex lg:flex-row-reverse lg:px-16 z-30 overflow-hidden lg:py-32">
				<DesktopRosterNavigation
					characters={bosses}
					activeCharacterIndex={activeBossIndex}
					handleActiveCharacterIndex={handleActiveCharacterIndex}
				/>
				<div className="lg:ml-6 lg:mr-8 w-full lg:w-[35%] lg:min-w-[520px] z-30 relative mb-[29rem] md:mb-96 lg:mb-0 3xl:mr-[10%] -mt-32 md:mt-0">
					<div className="mb-8 z-30">
						<Heading
							type={3}
							className={`uppercase text-center lg:text-left mb-6 mt-8 z-30`}
							color={CONFIG_COLORS.LIGHT_1}
						>
							<span
								className={`text-xl z-30 ${
									inView ? `top-left-corner-fade-in` : `opacity-0`
								}`}
							>
								{subHeader}
							</span>
							<br />{' '}
							<span
								className={`${inView ? `top-left-corner-fade-in-slow` : `opacity-0`}`}
							>
								{header}
							</span>
						</Heading>
						<p
							className={`text-light1 text-center lg:text-left text-lg z-30 ${
								inView ? `delayed-fade-in-opacity` : `opacity-0`
							}`}
						>
							{description}
						</p>
					</div>

					<MobileRosterNavigation
						characters={bosses}
						activeCharacterIndex={activeBossIndex}
						handleActiveCharacterIndex={handleActiveCharacterIndex}
					/>

					<div className="flex justify-between items-start my-6 lg:hidden z-30">
						<div className="h-[1px] w-[calc(50%-0.75rem)] lg:w-[calc(50%-6.75rem)] bg-black1" />
						<SVG src={diamondIcon} className="fill-black1 h-2 relative -top-1" />
						<div className="h-[1px] w-[calc(50%-0.75rem)] lg:w-[calc(50%-6.75rem)] bg-black1" />
					</div>
					<div className="relative z-30">
						<div className="overflow-hidden w-full h-full relative">
							{bosses.map((boss, index) => (
								<div
									key={index}
									className={` ${
										inView ? `grow-fade-in-middle-delayed` : `opacity-0`
									} w-full scale-95 bg-black2 bg-opacity-50 transition-all duration-500 delay-200 ${
										index === activeBossIndex
											? `translate-x-[0%]`
											: `absolute translate-x-[150%]`
									}`}
								>
									<SVG
										src={diamondIcon}
										className={`fill-light2 h-3 absolute -left-1 top-[51%] scale-95 hidden lg:block`}
									/>
									<SVG
										src={diamondIcon}
										className={`fill-light2 h-3 absolute -right-1 top-[51%] scale-95 hidden lg:block `}
									/>
									<div
										key={index}
										className={`clip-roster-description lg:border-[1px] border-light2 lg:p-4 w-full`}
									>
										<div className="lg:flex lg:justify-center items-center mb-2 lg:mb-0 lg:h-36">
											<Heading
												type={4}
												className="font-display uppercase overflow-hidden text-center text-magenta"
												color={CONFIG_COLORS.MAGENTA}
											>
												{boss?.name}
											</Heading>
										</div>
										{/* {character.gameplayDescription && ( */}
										{boss?.description && (
											<>
												<div className="hidden lg:flex justify-between items-center py-4">
													<div className="h-[1px] w-[calc(50%-3rem)] bg-light2"></div>
													<p className="uppercase text-light2 text-lg tracking-widest">
														About
													</p>
													<div className="h-[1px] w-[calc(50%-3rem)] bg-light2"></div>
												</div>
												<div className="lg:h-32 w-full flex items-end">
													<p className=" overflow-hidden text-lg text-light2">
														{boss?.description}
													</p>
												</div>
											</>
										)}

										{/* {character.abilities.length > 0 && (
											<div className="items-center hidden lg:flex justify-between">
												<div className="flex items-center justify-between px-10 pl-0 flex-1">
													{character?.abilities?.map((ability, index) => {
														if (index === activeAbilityIndex) {
															return (
																<div
																	className=""
																	onClick={handleActiveAbilityIndex(index)}
																	key={`charAbility-${index}`}
																>
																	<MediaItem
																		format={ability.selectedIcon.format}
																		isImage={ability.selectedIcon.isImage}
																		gatsbyImageData={ability.selectedIcon.gatsbyImageData}
																		svgSrc={ability.selectedIcon.url}
																		mediaItemClasses="w-12 cursor-pointer hover:scale-110 transition-all duration-300"
																	/>
																</div>
															)
														} else {
															return (
																<div
																	onClick={handleActiveAbilityIndex(index)}
																	key={`charAbility-${index}`}
																>
																	<MediaItem
																		format={ability.defaultIcon.format}
																		isImage={ability.defaultIcon.isImage}
																		gatsbyImageData={ability.defaultIcon.gatsbyImageData}
																		svgSrc={ability.defaultIcon.url}
																		mediaItemClasses="w-12 cursor-pointer hover:scale-110 transition-all duration-300"
																	/>
																</div>
															)
														}
													})}
												</div>
												<Heading
													type={6}
													className="uppercase italic flex-1 text-center whitespace-nowrap !text-base"
													color={CONFIG_COLORS.LIGHT_2}
												>
													{character?.abilities[activeAbilityIndex].name}
												</Heading>
											</div>
										)} */}
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
				{bosses.map((character, index) => (
					<div
						key={index}
						className={` absolute left-0 right-0 w-full xs:w-3/5 sm:w-2/5 mx-auto h-96 lg:h-[30rem] overflow-visible flex justify-center items-start bottom-12 sm:bottom-0 md:bottom-12 lg:bottom-28  lg:left-6 lg:hidden`}
					>
						<div className="absolute left-0 -bottom-72 w-full h-full"></div>
						<MediaItem
							format={character.featuredImage.format}
							isImage={character.featuredImage.isImage}
							gatsbyImageData={character.featuredImage.gatsbyImageData}
							svgSrc={character.featuredImage.url}
							mediaItemClasses={` ${
								inView ? `fade-in-opacity` : `opacity-0`
							} max-w-lg lg:max-w-none lg:w-[40rem] transition-all ease-in-out duration-500`}
							className={`overflow-visible ${
								index !== activeBossIndex
									? `absolute inactive-featured-image`
									: `relative`
							} transition-all ease-in-out duration-1000`}
						/>
					</div>
				))}

				<div
					className={`hidden lg:block h-[425px] bg-teal w-1 absolute left-12  ${
						inView ? `fade-in-from-top` : `opacity-0`
					}`}
				>
					<p
						className={`${
							inView ? `fade-in-from-top-delayed` : `opacity-0`
						} hidden lg:block absolute -left-4 uppercase text-3xl text-light2 text-sideways top-[28rem] whitespace-nowrap`}
					>
						{bosses[activeBossIndex].name}
					</p>
				</div>

				<MobileThumbnailCarousel
					characters={bosses}
					activeCharacterIndex={activeBossIndex}
				/>
			</Blade>

			{bosses.map((boss, index) => (
				<div
					className="absolute h-full top-0 z-20 lg:left-[-10%] xl:left-[5%] overflow-hidden max-w-lg lg:max-w-none lg:w-[42rem] 3xl:ml-[10%] flex items-center justify-center"
					key={index}
				>
					<MediaItem
						format={boss.featuredImage.format}
						isImage={boss.featuredImage.isImage}
						gatsbyImageData={boss.featuredImage.gatsbyImageData}
						svgSrc={boss.featuredImage.url}
						mediaItemClasses={`${
							inView ? `fade-in-opacity` : `opacity-0`
						} max-w-lg  lg:max-w-none lg:w-[42rem] transition-all ease-in-out duration-500`}
						className={`overflow-hidden relative z-20 hidden lg:block ${
							index === activeBossIndex ? `` : `inactive-featured-image`
						} transition-all ease-in-out duration-1000 `}
					/>
				</div>
			))}
		</section>
	)
}

export default BossCarouselBlade
