import React from 'react'
import cx from 'classnames'
import diamondIcon from '@/images/video-diamond.svg'
import diamondBtmLeftIcon from '@/images/frame-diamond-bottom-left.svg'
import diamondBtmRightIcon from '@/images/frame-diamond-bottom-right.svg'
import diamondTopLeftIcon from '@/images/frame-diamond-top-left.svg'
import diamondTopRightIcon from '@/images/frame-diamond-top-right.svg'
import overlayImg from '@/images/video-overlay-graphic.svg'
import SVG from '../SVG'
import { useInView } from 'react-intersection-observer'

interface IVideoBorderProps {
	children: React.ReactNode
	className?: string
	hasOverlayImage?: boolean
}

const VideoBorder: React.FC<IVideoBorderProps> = ({
	children,
	className,
	hasOverlayImage = false,
}) => {
	const classes = cx(`group`, className)
	const { ref, inView, entry } = useInView({
		/* Optional options */
		threshold: 0.3,
		triggerOnce: true,
	})
	return (
		<div className={classes} ref={ref}>
			<SVG
				src={diamondTopLeftIcon}
				className={`absolute w-3 -top-3 -left-[12px] z-10 fill-magenta ${
					inView ? `delayed-fade-in-opacity` : `opacity-0`
				}`}
			/>
			<SVG
				src={diamondTopRightIcon}
				className={`absolute w-3 -top-3 -right-[12px] z-10 fill-magenta ${
					inView ? `delayed-fade-in-opacity` : `opacity-0`
				}`}
			/>
			<SVG
				src={diamondBtmLeftIcon}
				className={`absolute w-3 -bottom-3 -left-[12px] z-10 fill-magenta ${
					inView ? `delayed-fade-in-opacity` : `opacity-0`
				}`}
			/>
			<SVG
				src={diamondBtmRightIcon}
				className={`absolute w-3 -bottom-3 -right-[12px] z-10 fill-magenta ${
					inView ? `delayed-fade-in-opacity` : `opacity-0`
				}`}
			/>

			<div
				className={`${
					inView ? `fade-in-from-middle` : `opacity-0`
				} absolute h-[1px] w-[calc(100%+25px)] right-[calc(50%-calc((100%+25px)/2))] bg-black3 -top-3`}
			>
				<div
					className={`${
						inView ? `fade-in-from-top-delayed` : `opacity-0`
					}  absolute h-16 lg:h-24 w-[1px] bg-black3 top-0 left-0`}
				></div>
				<div
					className={`${
						inView ? `fade-in-from-top-delayed` : `opacity-0`
					} absolute h-16 lg:h-24 w-[1px] bg-black3 top-0 right-0`}
				></div>
			</div>
			<div
				className={`${
					inView ? `fade-in-from-middle` : `opacity-0`
				} absolute h-[1px] w-[calc(100%+25px)] right-[calc(50%-calc((100%+25px)/2))] bg-black3 -bottom-3`}
			>
				<div
					className={`${
						inView ? `fade-in-from-bottom-delayed` : `opacity-0`
					} absolute h-16 lg:h-24 w-[1px] bg-black3 bottom-0 left-0`}
				></div>
				<div
					className={`${
						inView ? `fade-in-from-bottom-delayed` : `opacity-0`
					} absolute h-16 lg:h-24 w-[1px] bg-black3 bottom-0 right-0`}
				></div>
			</div>

			<div
				className={`video-clip w-full h-full md:max-w-3xl mx-auto absolute bg-black3 scale-y-[1.03] scale-x-[1.02]  ${
					inView ? `grow-fade-in-middle-video` : `opacity-0`
				}`}
			></div>
			<SVG
				src={diamondIcon}
				className={`absolute h-12 -left-6 top-[calc(50%-1.5rem)] fill-black3  group-hover:fill-magenta duration-300 ${
					inView ? `delayed-fade-in-opacity` : `opacity-0`
				}`}
			/>
			<SVG
				src={diamondIcon}
				className={`absolute h-12 -right-6 top-[calc(50%-1.5rem)] fill-black3 group-hover:fill-magenta duration-300 ${
					inView ? `delayed-fade-in-opacity` : `opacity-0`
				}`}
			/>
			{children}
			{hasOverlayImage && <SVG src={overlayImg} className="absolute z-10" />}
		</div>
	)
}

export default VideoBorder
