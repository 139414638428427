import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import SVG from 'react-inlinesvg'

import Blade from '../Blade'

import honeycomb from '@/images/honeycomb-light1.png'
import chevronIcon from '@/images/dropdown-chevron.svg'
import Lottie from 'react-lottie'
import * as hexBgWhite from '@/images/lotties/hex-bg-white.json'
import Heading from '../Heading/Heading'

interface IFaq {
	backgroundImage: any // TODO: typing
	thumbnail: any // TODO: typing
	heading: string
	body: string
}

interface IFaqsBladeProps {
	heading: string | null
	faqs: IFaq[]
}

const hexBgLottieOptions = {
	loop: true,
	autoplay: true,
	animationData: hexBgWhite,
	rendererSettings: {
		preserveAspectRatio: 'xMidYMid slice',
	},
}

const FaqsBlade: React.FC<IFaqsBladeProps> = ({
	key,
	heading,
	faqs,
	characterImage,
}) => {
	const [index, setIndex] = useState<number>(0)

	return (
		<div className="bg-black3 overflow-hidden pt-24 -mt-24">
			<div className="faqs-blade pb-20 md:pb-48 lg:pb-0 relative z-20 bg-gradient-to-b from-light1 to-teal">
				<img
					src={honeycomb}
					className="w-[100vw] h-auto absolute top-48 lg:top-64 left-1/2 transform -translate-x-1/2 opacity-50 z-0"
				/>
				{/* <div className="w-full h-auto absolute top-20 lg:top-20 left-1/2 transform -translate-x-1/2 opacity-50 z-0 cursor-default">
					<Lottie options={hexBgLottieOptions} height={400} width={`100%`} />
				</div>
				<div className="w-full h-auto absolute top-20 lg:top-20 left-1/2 transform -translate-x-1/2 translate-y-[100%] opacity-50 z-0">
					<Lottie options={hexBgLottieOptions} height={400} width={`100%`} />
				</div> */}

				<Blade className="max-w-8xl mx-auto">
					<div className="flex flex-col-reverse lg:flex-row items-start -m-8">
						<div className="w-full lg:w-2/3 p-8">
							<div className="flex flex-col -m-3">
								{faqs?.map(({ question, answer }: IFaq, _index: number) => (
									<div key={_index} className="p-3">
										<div className="pyt-4 pxx-6 relative border border-b-0 border-black3">
											<button
												className="flex items-center justify-between w-full px-6 py-4 pr-0 text-left"
												onClick={() => setIndex(_index)}
											>
												<Heading
													type={3}
													className=" !text-xl !md:text-2xl text-black2 uppercase"
												>
													{question}
												</Heading>

												<div>
													<span className="block w-12 h-12 ml-8 relative border-l border-black3">
														<span
															className={`block absolute top-1/2 left-1/2  -translate-x-1/2 -translate-y-1/2 transform ${
																_index === index ? `rotate-90` : `rotate-0`
															} origin-center transition-all`}
														>
															<SVG
																src={chevronIcon}
																className="w-2 h-auto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
															/>
														</span>
													</span>
												</div>
											</button>

											<div
												className={`${
													_index === index ? `h-auto px-6 pb-4` : `h-0`
												} overflow-hidden transition-all`}
											>
												<div className="relative">
													<span
														className={`block ${
															_index === index ? `w-full` : `w-0`
														} h-px absolute top-0 left-0 bg-black3 transition-all duration-500 z-20`}
													/>
													<ReactMarkdown
														className={`pt-4 transform whitespace-pre-line ${
															_index === index
																? `opacity-100 visible translate-x-0`
																: `opacity-0 invisible translate-x-4`
														} transition-all duration-500`}
													>
														{answer}
													</ReactMarkdown>
												</div>
											</div>

											<span className="flex w-full h-px absolute bottom-0 left-0">
												<span className="flex-1 h-px bg-black3 transition-all duration-300" />
												<span
													className={`${
														_index === index ? `w-0` : `w-16`
													} h-px transition-all duration-300 overflow-hidden`}
												>
													<span
														className={`${
															_index === index ? `clipped-diamond-shut` : `clipped-diamond`
														} block w-2 h-3 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-black3 transition-all duration-300`}
													/>
												</span>
												<span className="flex-1 h-px bg-black3 transition-all duration-300" />
											</span>
										</div>
									</div>
								))}
							</div>
						</div>

						<div className="w-full lg:w-1/3 p-8 text-center">
							{heading ? (
								<Heading
									type={2}
									className="max-w-lg mx-auto text-2xl md:text-3xl lg:text-4xl text-black2 text-left uppercase"
								>
									{heading}
								</Heading>
							) : null}

							{characterImage ? (
								<span className="hidden lg:inline-block">
									<GatsbyImage
										image={getImage(characterImage)}
										className=" w-full max-w-[16rem] lg:max-w-xs mx-auto lg:mt-12 !absolute lg:!relative -bottom-12 lg:bottom-auto right-0 md:right-1/4 lg:right-auto animate-bounce-slow pointer-events-none select-none z-20"
									/>
								</span>
							) : null}
						</div>
					</div>
				</Blade>

				<span className="clip-side-triangle right block w-6 md:w-12 h-24 md:h-48 absolute top-0 right-0 transform -translate-y-1/2 bg-teal z-20" />
			</div>
		</div>
	)
}

export default FaqsBlade
