import { useLightboxState } from '@/context'
import React, { useEffect } from 'react'
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'

import BackgroundImage from 'gatsby-background-image'
import { getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import { IoIosClose } from 'react-icons/io'

import Portal from '@/hoc/Portal'

interface ILightboxProps {}

const Lightbox: React.FC<ILightboxProps> = () => {
	const {
		lightboxOpen,
		setLightboxOpen,
		activeLightboxImage,
		setActiveLightboxImage,
		lightboxImages,
		setLightboxImages,
		lightboxVideo,
		setLightboxVideo,
	} = useLightboxState()

	const handleLightboxClose = () => {
		setLightboxOpen(false)

		setTimeout(() => {
			setLightboxImages([]) // empty context
			setLightboxVideo(false) // empty context
		}, 250)
	}

	useEffect(() => {
		const keyDownHandler = (event: KeyboardEvent) => {
			if (event.key === `Escape`) {
				event.preventDefault()

				// 👇️ your logic here
				handleLightboxClose()
			}
		}

		document.addEventListener(`keydown`, keyDownHandler)

		return () => {
			document.removeEventListener(`keydown`, keyDownHandler)
		}
	}, [])

	return (
		<Portal
			{...{
				className: `${lightboxOpen ? `block` : `hidden`}`,
			}}
		>
			<div
				className={`grid grid-cols-14 w-full h-full px-10 pb-20 xl:p-20 fixed top-0 left-0 text-center text-white transition-all ${
					lightboxOpen
						? `opacity-100 visible pointer-events-auto`
						: `opacity-0 invisible pointer-events-none`
				} z-[100]`}
			>
				<div className="bg-black5 opacity-60 w-full h-full absolute top-0 left-0" />
				<div
					className="z-20 absolute top-4 right-4 p-3 bg-black cursor-pointer"
					onClick={() => handleLightboxClose()}
				>
					<div onClick={() => handleLightboxClose()}>
						<IoIosClose
							size={60}
							className="fill-teal hover:fill-light2 transition-all duration-300"
						/>
					</div>
				</div>

				{lightboxImages.length > 1 && (
					<div
						className="hidden lg:flex items-center justify-center cursor-pointer"
						onClick={() =>
							setActiveLightboxImage(Math.max(0, activeLightboxImage - 1))
						}
					>
						<div className="w-12 xl:w-16 h-12 xl:h-16 rounded-full bg-black flex items-center justify-center relative cursor-pointer group">
							<AiOutlineLeft className="text-white w-6 xl:w-8 h-6 xl:h-6" />
							<span className="w-[120%] h-[120%] border border-primary border-dashed absolute rounded-full group-hover:opacity-100 transition-all opacity-0" />
						</div>
					</div>
				)}
				<div className="col-span-full lg:col-span-12 lg:col-start-1 lightbox__inner flex items-center justify-center mx-auto w-full h-full top-0 left-0 relative">
					{lightboxImages.length > 0 && (
						<>
							{lightboxImages.map((full: IGatsbyImageData, i: number) => {
								const image = getImage(full)
								const bg = convertToBgImage(image)

								return (
									<>
										<div
											className={`lightbox__image w-full h-full absolute top-0 left-0 transition-all duration-500 ${
												i === activeLightboxImage
													? `opacity-100 visible pointer-events-auto`
													: `opacity-0 invisible pointer-events-none`
											}`}
										>
											<BackgroundImage
												{...bg}
												className="w-full h-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-contain bg-center bg-no-repeat"
												style={{ position: null }}
											/>
										</div>
									</>
								)
							})}
						</>
					)}
					{lightboxVideo && (
						<div className="w-full pt-[50%] -mt-[2.5rem] relative">
							{lightboxVideo?.providerUid ? (
								<iframe
									type="text/html"
									src={`https://www.youtube.com/embed/${lightboxVideo?.providerUid}?modestbranding=1&rel=0&playsinline=0&allowfullscreen=true`}
									frameBorder="0"
									allowfullscreen="allowfullscreen"
									className="w-full h-full absolute top-0 left-0"
								/>
							) : null}

							{lightboxVideo?.video?.mp4Url ? (
								<video
									playsInline={true}
									frameBorder="0"
									autoPlay={true}
									muted={false}
									loop={true}
									className="w-full h-full absolute top-0 left-0 bg-black object-cover"
								>
									<source src={lightboxVideo?.video?.mp4Url} type="video/mp4" />
								</video>
							) : null}
						</div>
					)}
				</div>

				{lightboxImages.length > 1 && (
					<div
						className="hidden lg:flex items-center justify-center cursor-pointer"
						onClick={() =>
							setActiveLightboxImage(
								Math.min(lightboxImages.length - 1, activeLightboxImage + 1)
							)
						}
					>
						<div className="w-12 xl:w-16 h-12 xl:h-16 rounded-full bg-black flex items-center justify-center relative cursor-pointer group">
							<AiOutlineRight className="text-white w-6 xl:w-8 h-6 xl:h-6" />
							<span className="w-[120%] h-[120%] border border-primary border-dashed absolute rounded-full group-hover:opacity-100 transition-all opacity-0" />
						</div>
					</div>
				)}
			</div>
		</Portal>
	)
}

export default Lightbox
