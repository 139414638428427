import React from 'react'
import cx from 'classnames'
import diamondIcon from '@/images/video-diamond.svg'
import diamondBtmLeftIcon from '@/images/frame-diamond-bottom-left.svg'
import diamondBtmRightIcon from '@/images/frame-diamond-bottom-right.svg'
import diamondTopLeftIcon from '@/images/frame-diamond-top-left.svg'
import diamondTopRightIcon from '@/images/frame-diamond-top-right.svg'
import overlayImg from '@/images/video-overlay-graphic.svg'
import SVG from '@/components/SVG'

interface IItemBorderProps {
	children: React.ReactNode
	className?: string
	hasOverlayImage?: boolean
}

const ItemBorder: React.FC<IItemBorderProps> = ({
	children,
	className,
	hasOverlayImage = false,
}) => {
	const classes = cx(``, className)

	return (
		<div className={classes}>
			<SVG
				src={diamondTopLeftIcon}
				className="absolute w-1.5 -top-1.5 -left-[6px] z-10 fill-black3"
			/>
			<SVG
				src={diamondTopRightIcon}
				className="absolute w-1.5 -top-1.5 -right-[6px] z-10 fill-black3"
			/>
			<SVG
				src={diamondBtmLeftIcon}
				className="absolute w-1.5 -bottom-1.5 -left-[6px] z-10 fill-black3"
			/>
			<SVG
				src={diamondBtmRightIcon}
				className="absolute w-1.5 -bottom-1.5 -right-[6px] z-10 fill-black3"
			/>

			{children}
			{hasOverlayImage && <SVG src={overlayImg} className="absolute z-10" />}
			<div className=" media-item-clip w-full h-full border border-black3 bg-transparent scale-x-[1.04] scale-y-[1.055] absolute top-0 left-0">
				<div className="w-full h-full">
					<div className="bg-light2" />
					<div className="bg-black3" />
				</div>
			</div>
		</div>
	)
}

export default ItemBorder
