import { graphql, Link, useStaticQuery } from 'gatsby'
import React from 'react'
import ArticleBox from '../ArticleBox'
import ArticleSection, { IArticleSectionProps } from '../ArticleSection'
import { IoIosArrowRoundForward } from 'react-icons/io'
import Heading from '../Heading/Heading'

import bgElement1 from '@/images/bg-elements-1.svg'
import bgElement2 from '@/images/bg-elements-2.svg'
import bgElement3 from '@/images/bg-elements-3.svg'
import bgElement4 from '@/images/bg-elements-4.svg'
import bgElement5 from '@/images/bg-elements-5.svg'
import SVG from '../SVG'

export interface IArticlePageBladeProps {
	__typename: string
	id: string
	key: number
	className: string
	articleBlocks: IArticleSectionProps[]
	categories: {
		id: string
		title: string
		slug: string
	}[]
}

const ArticlePageBlade: React.FC<IArticlePageBladeProps> = ({
	className,
	articleBlocks,
	categories,
}) => {
	const allArticles = useStaticQuery(graphql`
		query {
			allDatoCmsNews {
				nodes {
					__typename
					id
					title
					slug
					category {
						title
						slug
					}
					coverImage {
						url
						gatsbyImageData
						alt
					}
					publishedAt
					content
				}
			}
		}
	`)

	return (
		<section className={`${className} bg-light2`}>
			<div className="absolute w-full h-full top-0 left-0 z-10">
				<div className="relative w-full h-full">
					<SVG
						src={bgElement1}
						className="absolute left-10 top-[16rem] w-10 fill-black3 delayed-fade-in-opacity"
					/>
					<SVG
						src={bgElement2}
						className="hidden lg:block absolute left-72 top-72 w-5 fill-black3 delayed-fade-in-opacity"
					/>
					<div className="absolute right-0 top-0 teal-triangle-bg">
						<SVG
							src={bgElement4}
							className="hidden lg:block bg-black3 h-2 w-4 absolute top-40 clipped-parallelogram-alt left-[5.1rem] delayed-fade-in-opacity"
						/>
						<SVG
							src={bgElement4}
							className="hidden lg:block bg-black3 h-2 w-4 absolute top-80 clipped-parallelogram-alt left-[10.8rem] delayed-fade-in-opacity"
						/>
					</div>
					<div className="w-[1px] h-[110%] absolute bg-black1 left-[60%] top-0 -rotate-[30deg] delayed-fade-in-opacity">
						<SVG
							src={bgElement1}
							className="absolute -left-12 bottom-[4rem] w-10 fill-black3 rotate-[30deg]"
						/>
						<SVG
							src={bgElement5}
							className="hidden lg:block absolute left-[-0.72rem] top-[18rem] w-4 rotate-[30deg]"
						/>
					</div>
				</div>
				<div className="h-full w-full absolute top-0 left-0 delayed-fade-in-opacity">
					<SVG
						src={bgElement3}
						className="absolute right-20 top-10 w-5 fill-light2"
					/>
					<SVG
						src={bgElement3}
						className="absolute right-56 top-60 w-5 fill-light2"
					/>
				</div>
			</div>
			<div className="max-w-6xl w-full mx-auto flex flex-col gap-8 pt-24 pb-16 px-4 lg:px-0 z-30">
				{articleBlocks.map(({ title, items }) => (
					<ArticleSection key={title} title={title} items={items} />
				))}
				{categories &&
					categories.map(({ id, title, slug }) => {
						const articles = allArticles.allDatoCmsNews.nodes.filter(
							(article: any) => article.category.slug === slug
						)

						return (
							<div key={id} className="flex flex-col gap-4">
								<div className="flex flex-col lg:flex-row lg:gap-4 items-center lg:items-end justify-center lg:justify-start">
									<Heading type={3}>{title}</Heading>
									{articles.length > 3 && (
										<Link
											to={`/news/${slug}`}
											className="text-teal text-lg lg:text-xl font-semibold uppercase"
										>
											VIEW MORE
											<IoIosArrowRoundForward className="inline-block w-6 h-6 lg:w-8 lg:h-8 ml-1 mb-1" />
										</Link>
									)}
								</div>

								<div className="grid lg:grid-cols-3 gap-4">
									{articles.slice(0, 3).map((article: any) => (
										<ArticleBox article={article} key={article.id} />
									))}
								</div>
							</div>
						)
					})}
			</div>
		</section>
	)
}

export default ArticlePageBlade
