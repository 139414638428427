import React from 'react'
import BackgroundImage from 'gatsby-background-image'
import { getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'

import Blade from '../Blade'
import { IButtonProps } from '../Blades'
import Button, { BTN_COLOR } from '../Button'
import Heading from '../Heading/Heading'
import Link from '../Link'

export interface ISimpleHeroBladeProps {
	__typename: string
	id: string
	backgroundImage: {
		gatsbyImageData: IGatsbyImageData
		title: string
	}
	mobileBackgroundImage: {
		gatsbyImageData: IGatsbyImageData
		title: string
	}
	backgroundBackdropColour: { hex: string }
	subheading: string | null
	heading: string | null
	body: string | null
	buttons: IButtonProps[]
	key: number
	className: string
}

const SimpleHeroBlade: React.FC<ISimpleHeroBladeProps> = ({
	key,
	className,
	...props
}) => {
	const {
		backgroundImage,
		mobileBackgroundImage,
		subheading,
		heading,
		body,
		buttons,
	} = props
	const background = convertToBgImage(getImage(backgroundImage.gatsbyImageData))
	const mobileBackground = convertToBgImage(
		getImage(mobileBackgroundImage.gatsbyImageData)
	)

	return (
		<div className="w-full py-20 md:py-32 lg:py-48 relative overflow-visible z-10 text-center md:text-left">
			<BackgroundImage
				{...mobileBackground}
				Tag="section"
				className="md:hidden w-full h-full absolute top-0 left-0 bg-bottom bg-cover overflow-visible z-0"
				style={{ position: `absolute` }}
				preserveStackingContext
			/>
			<BackgroundImage
				{...background}
				Tag="section"
				className="hidden md:block w-full h-full absolute top-0 right-0 bg-right bg-cover overflow-visible z-0"
				style={{ position: `absolute` }}
				preserveStackingContext
			/>
			<div className="absolute w-full h-full top-0  simple-hero-blade-gradient"></div>

			<span className="hidden xl:flex flex-col h-full items-center justify-start pb-56 absolute top-0 right-0 uppercase select-none pointer-events-none z-10">
				<span className="inline-flex items-center justify-center w-24 h-24 transform origin-center rotate-90 text-center">
					Menu
				</span>
				<span className="flex-1 w-px h-full bg-black1" />
			</span>

			<Blade className="max-w-8xl mx-auto pb-[100%]">
				<div className="relative flex flex-col md:flex-row justify-center items-center top-0 left-0 h-full w-full ">
					<div className="w-full h-1/2 md:h-full flex flex-col flex-1 overflow-visible pb-full">
						{subheading ? (
							<span className="block text-lg md:text-2xl uppercase italic">
								{subheading}
							</span>
						) : null}

						{heading ? (
							<Heading
								type={1}
								className="max-w-lg mx-auto md:mx-0  text-4xl md:text-5xl lg:text-6xl uppercase"
							>
								{heading}
							</Heading>
						) : null}

						{body ? (
							<p className="max-w-md mx-auto md:mx-0 mt-4 md:mt-8 text-lg font-light">
								{body}
							</p>
						) : null}

						{buttons?.length > 0 ? (
							<div className="flex flex-col md:flex-row w-full justify-start items-center mt-8 md:mt-12">
								{buttons.map((button: IButtonProps, i: number) => (
									<Button
										key={`charBladeBtn-${i + 1}`}
										icon={button.buttonIcon}
										text={button.text}
										color={button.buttonColour}
										hasIcon={button.hasIcon}
										innerBtnClassName={`z-10 w-full `}
										containerClassName={`${i + 1 === 1 ? `mr-8` : ``}`}
										link={button.link}
										isExternal={false}
										hoverColor={BTN_COLOR.TEAL}
									/>
								))}
							</div>
						) : null}
					</div>
				</div>
			</Blade>
		</div>
	)
}

export default SimpleHeroBlade
