import React from 'react'
import RosterCarouselNavigation from './RosterCarouselNavigation'
import arrowLight from '@/images/arrow-light.svg'
import { ICharacter } from '..'
import SVG from '@/components/SVG'
import { useInView } from 'react-intersection-observer'

interface IMobileRosterNavigationProps {
	characters: ICharacter[]
	activeCharacterIndex: number
	handleActiveCharacterIndex: (direction: string) => () => void
}

const MobileRosterNavigation: React.FC<IMobileRosterNavigationProps> = ({
	characters,
	activeCharacterIndex,
	handleActiveCharacterIndex,
}) => {
	const { ref, inView, entry } = useInView({
		/* Optional options */
		threshold: 0.3,
		triggerOnce: true,
	})
	return (
		<div className="flex items-center justify-between lg:hidden" ref={ref}>
			<div
				className={`cursor-pointer group ${
					inView ? `delayed-fade-in-opacity` : `opacity-0`
				}`}
				onClick={handleActiveCharacterIndex(`decrease`)}
			>
				<SVG
					src={arrowLight}
					className="w-16 h-16 -rotate-90 mr-4 fill-light2 text-light3"
				/>
			</div>
			<RosterCarouselNavigation
				characters={characters}
				activeCharacterIndex={activeCharacterIndex}
			/>
			<div
				className={`cursor-pointer group ${
					inView ? `delayed-fade-in-opacity` : `opacity-0`
				}`}
				onClick={handleActiveCharacterIndex(`increase`)}
			>
				<SVG
					src={arrowLight}
					className="w-16 h-16 rotate-90 ml-4 fill-light2 text-light3"
				/>
			</div>
		</div>
	)
}

export default MobileRosterNavigation
