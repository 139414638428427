import ThumbnailBorder from '@/components/ImageBorders/ThumbnailBorder'
import MediaItem from '@/components/MediaItem'
import React from 'react'
import { ICharacter } from '..'
import { useInView } from 'react-intersection-observer'

interface IThumbnailCarouselProps {
	characters: ICharacter[]
	activeCharacterIndex: number
}

const MobileThumbnailCarousel: React.FC<IThumbnailCarouselProps> = ({
	characters,
	activeCharacterIndex,
}) => {
	const { ref, inView, entry } = useInView({
		/* Optional options */
		threshold: 0.3,
		triggerOnce: true,
	})
	const prevCharIndex =
		activeCharacterIndex - 1 >= 0
			? activeCharacterIndex - 1
			: characters.length - 1
	const nextCharIndex =
		activeCharacterIndex + 1 <= characters.length - 1
			? activeCharacterIndex + 1
			: 0

	return (
		<div
			ref={ref}
			className={`${
				inView ? `fade-in-opacity` : `opacity-0`
			} flex justify-between items-center absolute bottom-0 left-0 right-0 h-[8rem] w-full max-w-sm mx-auto mb-4 lg:hidden overflow-hidden`}
		>
			{characters.map((character, index) => {
				const prevImageClasses = `absolute translate-x-[5%] 3xs:translate-x-[25%] 2xs:translate-x-[30%] opacity-100`
				const activeImageClasses = `absolute translate-x-[100%] 3xs:translate-x-[125%] 2xs:translate-x-[135%] opacity-100`
				const nextImageClasses = `absolute translate-x-[230%] 3xs:translate-x-[255%] 2xs:translate-x-[270%] opacity-100`
				const hiddenImagesClasses = `absolute translate-x-[100%] opacity-0 translate-y-[200%]`

				return (
					<ThumbnailBorder
						className={`${
							index === prevCharIndex
								? prevImageClasses
								: index === activeCharacterIndex
								? activeImageClasses
								: index === nextCharIndex
								? nextImageClasses
								: hiddenImagesClasses
						} transition-all duration-500 delay-300`}
						active={activeCharacterIndex === index}
						key={index}
					>
						<MediaItem
							format={character.thumbnail.format}
							isImage={character.thumbnail.isImage}
							gatsbyImageData={character.thumbnail.gatsbyImageData}
							svgSrc={character.thumbnail.url}
							mediaItemClasses={`max-w-lg transition-all duration-500 delay-300`}
						/>
					</ThumbnailBorder>
				)
			})}
		</div>
	)
}

export default MobileThumbnailCarousel
