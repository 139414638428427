import cx from 'classnames'
import React, { BaseSyntheticEvent } from 'react'
import diamondIcon from '@/images/video-diamond.svg'
import SVG from '../SVG'

interface ITextInputProps {
	className?: string
	label?: string
	name: string
	value?: string
	placeholder: string
	type?: string
	placeholderClass?: string
	disabled?: boolean
	required?: boolean
	autoComplete?: string
	onChange: (e: BaseSyntheticEvent) => void
}

const TextInput: React.FC<ITextInputProps> = ({
	className,
	label,
	name,
	value,
	placeholder,
	type = `text`,
	placeholderClass,
	disabled = false,
	required = false,
	autoComplete,
	onChange,
}) => {
	const classes = cx(`flex relative mx-3 md:w-72 md:min-w-sm`, className)
	return (
		<div className={classes}>
			{label && <label htmlFor={name}>{label}</label>}
			<SVG
				src={diamondIcon}
				className="absolute h-4 -left-3 top-[calc(50%-0.5rem)] fill-light2"
			/>
			<SVG
				src={diamondIcon}
				className="absolute h-4 -right-3 top-[calc(50%-0.5rem)] fill-light2"
			/>
			<input
				{...{
					type,
					name,
					value,
					placeholder: `${placeholder} ${required ? `(Required)` : ``}`,
					disabled,
					required,
					autoComplete,
					className: cx(
						`text-input-clip py-5 tracking-wide text-center text-sm font-thin placeholder-font-thin placeholder-black1 px-4 bg-light2 w-full`,
						placeholderClass,
						{ disabled }
					),
					onChange: (e) => onChange(e),
				}}
			></input>
		</div>
	)
}

export default TextInput
