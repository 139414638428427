import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import ReactMarkdown from 'react-markdown'
import Heading from '../Heading/Heading'
import { CONFIG_COLORS } from '@/types'

import './styles.scss'
import MediaItem from '../MediaItem'

interface FullscreenLandingPageProps {
	heading: string
	content: string
	image: {
		gatsbyImageData: any
		alt: string
		url: string
	}
	backgroundImage?: {
		gatsbyImageData: any
		alt: string
		url: string
	}
	className: string
}

const FullscreenLandingPage: React.FC<FullscreenLandingPageProps> = ({
	heading,
	content,
	image,
	className,
	backgroundImage,
}) => {
	return (
		<div
			className={`bg-light2 ${className} flex flex-col items-center justify-center relative overflow-clip lg:px-4`}
		>
			{backgroundImage && (
				<div className="z-0 absolute top-0 left-0 w-full h-full brightness-75">
					<MediaItem
						className="w-full h-full object-cover"
						isImage={true}
						format="image"
						gatsbyImageData={backgroundImage.gatsbyImageData}
						mediaItemClasses={`w-full h-full object-cover`}
						animateIn={`fade-in-bg-video`}
						animateStart={`opacity-0`}
						svgSrc={backgroundImage.url}
					/>
				</div>
			)}

			<div className="max-w-7xl mx-auto flex flex-col md:flex-row items-center justify-between z-20 after:bg-light1/80 after:absolute after:-z-10 after:top-0 after:left-0 after:bottom-0 after:right-0 md:after:left-1 md:after:top-1 md:after:right-1 md:after:bottom-1 full-screen-landing-bg-clip pt-20 lg:pt-6 px-8 lg:px-24 pb-4 relative border-t border-b border-black5">
				<div className="flex flex-col justify-center items-center">
					<Heading
						type={1}
						size="sm"
						color={CONFIG_COLORS.TEAL}
						className="text-4xl lg:text-5xl xl:text-5xl mb-8"
					>
						{heading}
					</Heading>
					<ReactMarkdown className="content text-dark2 text-2xl font-semibold text-black1 whitespace-pre-line">
						{content}
					</ReactMarkdown>
				</div>
				<div className="w-[80%] relative">
					<GatsbyImage
						image={image.gatsbyImageData}
						alt={image.alt}
						objectFit="contain"
						objectPosition="50% 50%"
						className="object-contain w-full h-full"
					/>
				</div>
				<span className="hidden lg:flex flex-col w-2 h-full absolute -top-0 -left-0 z-10">
					<span className="flex-1 border-l border-black5" />
					<span className="h-[6.3rem] lg:h-24 w-2 relative">
						<span className="block w-16 lg:w-[4.45rem] h-px absolute top-0 left-0 transform rotate-[65deg] lg:rotate-[42deg] origin-top-left bg-black5 z-30" />
						<span className="block w-16 lg:w-[4.45rem] h-px absolute bottom-0 left-0 transform -rotate-[65deg] lg:-rotate-[42deg] origin-bottom-left bg-black5 z-30" />
					</span>
					<span className="flex-1 border-l border-black5" />
				</span>
				<span className="hidden lg:flex flex-col w-2 h-full absolute -top-0 -right-0 z-10">
					<span className="flex-1 border-r border-black5" />
					<span className="h-[6.3rem] lg:h-24 w-2 relative">
						<span className="block w-16 lg:w-[4.45rem] h-px absolute top-0 right-0 transform -rotate-[65deg] lg:-rotate-[42deg] origin-top-right bg-black5 z-30" />
						<span className="block w-16 lg:w-[4.45rem] h-px absolute bottom-0 right-0 transform rotate-[65deg] lg:rotate-[42deg] origin-bottom-right bg-black5 z-30" />
					</span>
					<span className="flex-1 border-r border-black5" />
				</span>
			</div>
		</div>
	)
}

export default FullscreenLandingPage
