import React, { useState } from 'react'
import { getImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import BackgroundImage from 'gatsby-background-image'

import bgTriangle from '@/images/bg-triangle.svg'
import carouselArrowLeft from '@/images/carousel-arrow-left.svg'
import carouselArrowRight from '@/images/carousel-arrow-right.svg'

import Blade from '../Blade'
import SVG from '../SVG'
import PinkNav from '../PinkNav'

import bgElement1 from '@/images/bg-elements-1.svg'
import bgElement2 from '@/images/bg-elements-2.svg'
import bgElement3 from '@/images/bg-elements-3.svg'
import bgElement4 from '@/images/bg-elements-4.svg'
import bgElement5 from '@/images/bg-elements-5.svg'

import './style.scss'
import Heading from '../Heading/Heading'

interface IFullscreenCarouselBlock {
	backgroundImage: any // TODO: typing
	thumbnail: any // TODO: typing
	heading: string
	body: string
	subHeading: string
}

interface IFullscreenCarouselBladeProps {
	subheading: string | null
	heading: string | null
	slides: IFullscreenCarouselBlock[]
}

const Slide = ({
	_index,
	index,
	setIndex,
	totalSlides,
	backgroundImage,
	thumbnail,
	heading,
	body,
}) => {
	const imageIndex = _index - index

	return imageIndex > -5 && imageIndex < 5 ? (
		<div
			className={`fullscreen-carousel-blade__slide index-${imageIndex} transform transition-all duration-500`}
			onClick={() => setIndex(_index)}
		>
			<div
				className={`${
					imageIndex === 0 ? `pt-[40%]` : `pt-0 h-32`
				} transition-all duration-500`}
			>
				<span className="fullscreen-carousel-blade__image-back block w-[calc(100%+6px)] h-[calc(100%+6px)] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-light1 transition-all overflow-hidden duration-500" />
				<BackgroundImage
					{...convertToBgImage(getImage(thumbnail))}
					className="fullscreen-carousel-blade__image w-full h-full absolute top-0 left-0 transition-all duration-500"
					style={{
						position: `absolute`,
						'-webkit-backface-visibility': `hidden`,
						'backface-visibility': `hidden`,
					}}
				/>
				<span className="fullscreen-carousel-blade__image-front block w-full h-full absolute top-0 left-0 bg-magenta opacity-0 transition-all" />
			</div>
		</div>
	) : null
}

const FullscreenCarouselBlade: React.FC<IFullscreenCarouselBladeProps> = ({
	subheading,
	heading,
	slides,
}) => {
	const [index, setIndex] = useState<number>(Math.floor(slides.length / 2)) // Set index in middle of slides
	// console.log({ slides })
	const handlePrev = () => {
		setIndex(Math.max(0, index - 1))
	}

	const handleNext = () => {
		setIndex(Math.min(slides.length - 1, index + 1))
	}

	return (
		<div className="fullscreen-carousel-blade relative bg-light3 overflow-hidden">
			<SVG
				src={bgTriangle}
				className="w-full max-w-full h-auto absolute bottom-1/3 left-1/2 transform -translate-x-1/2 opacity-50 z-40 pointer-events-none select-none"
			/>

			{slides?.map(
				({ backgroundImage }: IFullscreenCarouselBlock, _index: number) => (
					<div
						key={_index}
						className={`w-full h-full absolute top-0 left-0 transition-all duration-500 ${
							_index === index ? `opacity-100 visible` : `opacity-0 invisible`
						} transition-all duration-1000 !z-0`}
						onClick={() => setIndex(_index)}
					>
						<BackgroundImage
							{...convertToBgImage(getImage(backgroundImage))}
							className="w-full h-full absolute top-0 left-0 z-0"
							style={{
								position: `absolute`,
								'-webkit-backface-visibility': `hidden`,
								'backface-visibility': `hidden`,
							}}
						/>
					</div>
				)
			)}

			<div className="character-grid-blade md:pb-12 lg:pb-20 relative z-60 text-center overflow-hidden">
				<Blade className="w-full max-w-full">
					<div className="max-w-8xl mx-auto relative z-60">
						{subheading ? (
							<span className="block mx-auto text-lg md:text-2xl text-light2 uppercase italic">
								{subheading}
							</span>
						) : null}

						{heading ? (
							<Heading
								type={1}
								className="max-w-2xl mx-auto  text-4xl md:text-5xl lg:text-6xl !text-light2 uppercase"
							>
								{heading}
							</Heading>
						) : null}
					</div>

					<div className="w-full pt-[60.1%] lg:pt-[25%] mt-8 md:mt-12 lg:mt-20 relative">
						{slides?.map((slide: IFullscreenCarouselBlock, _index: number) => (
							<Slide
								key={_index}
								{...{ ...slide, _index, index, setIndex, totalSlides: slides.length }}
							/>
						))}

						<button
							className={`group hidden xl:inline-flex items-center justify-center w-12 h-12 absolute top-1/2 left-[20%] transform -translate-y-1/2 bg-black5 text-light1 rounded-full text-center z-60 ${
								index === 0 ? `scale-75 opacity-50 pointer-events-none` : `opacity-100`
							} transition-all`}
							onClick={handlePrev}
						>
							<span className="block w-12 h-12 absolute top-0 left-0 transform origin-center group-hover:scale-110 bg-black5 text-light1 rounded-full transition-all" />
							<SVG className="w-4 h-auto transform" src={carouselArrowLeft} />
						</button>

						<button
							className={`group hidden xl:inline-flex items-center justify-center w-12 h-12 absolute top-1/2 left-[80%] right-0 transform -translate-x-full -translate-y-1/2 bg-black5 text-light1 rounded-full text-center z-60 ${
								index === slides.length - 1
									? `scale-75 opacity-50 pointer-events-none`
									: `opacity-100`
							} transition-all`}
							onClick={handleNext}
						>
							<span className="block w-12 h-12 absolute top-0 left-0 transform origin-center group-hover:scale-110 bg-black5 text-light1 rounded-full transition-all" />
							<SVG className="w-4 h-auto transform" src={carouselArrowRight} />
						</button>
					</div>

					<div className="flex flex-col-reverse md:flex-col items-center justify-center w-full lg:w-1/2 max-w-6xl mx-auto text-light2 uppercase">
						<div className="mt-8 md:my-8">
							<PinkNav
								{...{
									count: slides?.length,
									index,
									setIndex,
									activeColor: `white`,
									clickable: false,
									className: `justify-center`,
								}}
							/>
						</div>

						<div className="max-w-xl mx-auto mt-8 md:mt-0 fade-in-opacity">
							<div className="w-full flex flex-col xl:flex-row items-center justify-center pb-8 mb-8 relative font-display">
								{slides[index].subHeading && (
									<span className="inline-flex items-center xl:pr-8 xl:mr-8 mb-2 xl:mb-0 xl:border-r xl:border-light2 font-medium text-3xl">
										{slides[index].subHeading}
									</span>
								)}

								<span className="text-2xl italic">{slides[index].heading}</span>

								<span className="flex items-center w-full h-px absolute bottom-0 left-0">
									<span className="flex-1 h-px bg-light1" />
									<span className="block clipped-diamond w-2 h-3 mx-4 bg-light1" />
									<span className="flex-1 h-px bg-light1" />
								</span>
							</div>
							<span className="font-light text-lg">{slides[index].body}</span>
						</div>
					</div>
				</Blade>
			</div>

			<SVG
				src={bgElement2}
				className="absolute left-32 top-56 w-6 fill-black3 pointer-events-none"
			/>
			<SVG
				src={bgElement2}
				className="absolute right-8 top-1/2 w-6 fill-black3 pointer-events-none"
			/>
			<SVG
				src={bgElement2}
				className="absolute right-20 bottom-32 w-6 fill-light1 pointer-events-none z-40"
			/>
		</div>
	)
}

export default FullscreenCarouselBlade
