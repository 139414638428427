import SVG from '@/components/SVG'
import arrowLight from '@/images/arrow-light.svg'
import React from 'react'
import { ICharacter } from '..'

import paginationEl1 from '@/images/pagination-element-1.svg'
import paginationEl2 from '@/images/pagination-element-2.svg'
import paginationEl3 from '@/images/pagination-element-3.svg'
import DesktopThumbnailCarousel from './DesktopThumbnailCarousel'
import { useInView } from 'react-intersection-observer'

interface IDesktopRosterNavigationProps {
	characters: ICharacter[]
	activeCharacterIndex: number
	handleActiveCharacterIndex: (direction: string) => () => void
}

const DesktopRosterNavigation: React.FC<IDesktopRosterNavigationProps> = ({
	characters,
	activeCharacterIndex,
	handleActiveCharacterIndex,
}) => {
	const { ref, inView, entry } = useInView({
		/* Optional options */
		threshold: 0.3,
		triggerOnce: true,
	})
	return (
		<div
			className="hidden items-start lg:flex h-[500px] xl:min-h-[600px]"
			ref={ref}
		>
			<div
				className={`flex flex-col justify-center ${
					inView ? `delayed-fade-in-opacity` : `opacity-0`
				}`}
			>
				<div
					className="cursor-pointer group"
					onClick={handleActiveCharacterIndex(`decrease`)}
				>
					<SVG
						src={arrowLight}
						className="w-12 h-12 mb-3 fill-light2 text-light3 group-hover:fill-black1 group-hover:opacity-100 transition-all duration-300"
					/>
				</div>
				<div
					className="cursor-pointer group"
					onClick={handleActiveCharacterIndex(`increase`)}
				>
					<SVG
						src={arrowLight}
						className="w-12 h-12 rotate-180 fill-light2 text-light3 group-hover:fill-black1 group-hover:opacity-100 transition-all duration-300"
					/>
				</div>
			</div>
			<div
				className={` h-full w-full flex justify-between items-stretch flex-col relative ml-4`}
			>
				<DesktopThumbnailCarousel
					characters={characters}
					activeCharacterIndex={activeCharacterIndex}
				/>
				<div
					className={`h-full w-0.5 bg-teal mx-auto absolute ${
						inView ? `fade-in-from-top` : `opacity-0`
					}`}
				/>
				{characters.map((c, i) => (
					<div
						key={`${i}`}
						className={`relative -left-[0.3rem] ${
							inView ? `delayed-fade-in-opacity` : `opacity-0`
						} `}
					>
						<SVG
							src={paginationEl2}
							className={`w-3 rotate-90 transition-all duration-500 ${
								i === activeCharacterIndex ? `` : `hidden`
							}`}
						/>
						<SVG
							src={paginationEl3}
							className={`absolute -top-2 -left-3.5 w-10 rotate-90  ${
								i === activeCharacterIndex ? `` : `hidden`
							}`}
						/>
						<SVG
							src={paginationEl1}
							className={`w-3 rotate-90 transition-all duration-500 ${
								i === activeCharacterIndex ? `hidden` : ``
							}`}
						/>
					</div>
				))}
			</div>
		</div>
	)
}

export default DesktopRosterNavigation
