import { putAddContacts } from '@/api/add-contacts'
import { CONFIG_COLORS } from '@/types'
import BackgroundImage from 'gatsby-background-image'
import {
	GatsbyImageProps,
	getImage,
	IGatsbyImageData,
} from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import React, { BaseSyntheticEvent, useEffect, useRef, useState } from 'react'
import Blade from '../Blade'
import { IButtonProps } from '../Blades'
import Button, { BTN_COLOR } from '../Button'
import Checkbox from '../FormFields/Checkbox'
import TextInput from '../FormFields/TextInput'
import Heading from '../Heading/Heading'
import MediaItem from '../MediaItem'
import CharacterImage from './CharacterImage'
import { CSSTransition } from 'react-transition-group'
import chunk from 'lodash.chunk'
import Link from '../Link'

import SVG from 'react-inlinesvg'
import videoPlayIcon from '@/images/video-play-icon.svg'

export interface ICharacterHeroBladeProps {
	__typename: string
	id: string
	backgroundImage: {
		gatsbyImageData: IGatsbyImageData
		title: string
	}
	backgroundBackdropColour: { hex: string }
	logo: {
		gatsbyImageData: GatsbyImageProps
		isImage: boolean
		format: string
		url: string
		className: string
	}
	characters: {
		artwork: {
			gatsbyImageData: GatsbyImageProps
			isImage: boolean
			format: string
			url: string
			className: string
		}
	}[]
	buttons: IButtonProps[]
	platforms: {
		namedIcon: {
			gatsbyImageData: GatsbyImageProps
			isImage: boolean
			format: string
			url: string
			className: string
		}
	}[]
	key: number
	className: string
}

const CharacterHeroBlade: React.FC<ICharacterHeroBladeProps> = ({
	className,
	...props
}) => {
	const { backgroundImage, buttons, characters, logo, platforms } = props
	const background = getImage(backgroundImage.gatsbyImageData)
	const bg = convertToBgImage(background)

	const [showSignUp, setShowSignUp] = useState<boolean>(false)
	const [emailAddress, setEmailAddress] = useState<string>(``)
	const [consent, setConsent] = useState<boolean>(false)
	const [hasSignedUp, setHasSignedUp] = useState<boolean>(false)
	const [signUpFormError, setSignUpFormError] = useState<any>(null)
	const [showHeader, setShowHeader] = useState<boolean>(true)
	const [isMounted, setIsMounted] = useState<boolean>(false)

	const [sliceIndex, setSliceIndex] = useState<number>(0)

	const _characters = chunk(characters, 3)
	const charactersLength = _characters.length

	const formRef = useRef(null)
	const handleAutoImages = () => {
		if (sliceIndex + 1 >= charactersLength) {
			setSliceIndex(0)
		} else {
			setSliceIndex(sliceIndex + 1)
		}
	}

	setTimeout(() => {
		handleAutoImages()
	}, 5000)

	useEffect(() => {
		setIsMounted(true)
	}, [])

	const handleSubmitSignUp = async () => {
		setHasSignedUp(false)
		setSignUpFormError(null)

		const res = await fetch(`https://evercoreheroes.com/v1/signup`, {
			method: `POST`,
			body: JSON.stringify({
				email_address: emailAddress,
			}),
		})
		const result = await res.json()
		if (result.message === `OK`) {
			setHasSignedUp(true)
		} else {
			setSignUpFormError(true)
		}
	}

	// 	body
	// :
	// (...)
	// bodyUsed
	// :
	// false
	// headers
	// :
	// Headers {}
	// ok
	// :
	// true
	// redirected
	// :
	// false
	// status
	// :
	// 200
	// statusText
	// :
	// ""
	// type
	// :
	// "cors"
	// url
	// :
	// "https://sg.velagames.org/v1/signup"

	const scrollToTrailer = () => {
		if (typeof window !== `undefined`) {
			if (typeof document !== `undefined`) {
				const signUpElement = document.getElementById(`text-media-blade`)
				signUpElement?.scrollIntoView({
					behavior: `smooth`,
					block: `start`,
				})
			}
		}
	}

	return (
		<div className="relative z-20 md:pb-0 bg-light2" id="character-hero-blade">
			<div className="w-full bg-right overflow-visible z-20 relative">
				<BackgroundImage
					{...bg}
					Tag="div"
					className="clipped-character-hero clipped-corner w-full bg-right overflow-visible z-20 relative"
					preserveStackingContext
				>

					<Blade className="bg-[rgba(0,0,0,0.8)] md:px-0 lg:px-0 pb-32">
						<div className="flex flex-col md:flex-row justify-end items-center top-0 left-0 h-full w-full">
							<div className="px-8 pt-[32rem] md:pt-52 w-full md:mr-10 md:w-[45%] md:py-60 my-auto min-h-[810px] flex flex-col justify-end md:justify-center md:items-center z-40">


								<CSSTransition
									in={showSignUp}
									timeout={300}
									classNames="fade"
									unmountOnExit
									nodeRef={formRef}
									onEnter={() => setShowHeader(false)}
									onExited={() => setShowHeader(true)}
								>
									<div
										className={`w-full md:w-[410px] lg:w-[500px] bg-black3 mb-12 md:mb-0  xl:mb-0 `}
										ref={formRef}
									>
										<div
											className={`w-full h-full bg-black3 border border-light2 scale-y-[0.94] scale-x-[0.95] relative px-8 py-8 `}
										>
											<div className="flex flex-col items-center justify-center w-full min-h-[260px]">
												{!hasSignedUp && (
													<>
														<Heading
															type={6}
															color={CONFIG_COLORS.LIGHT_2}
															className="italic uppercase mb-4 !text-xl text-center"
														>
															Sign-up for exclusive updates and early playtest access
														</Heading>
														<form className="flex flex-col justify-center items-center">
															<TextInput
																{...{
																	className: `bg-light-2 w-full mb-6`,
																	name: `email_address`,
																	type: `email`,
																	placeholder: `Enter Email`,
																	required: true,
																	autoComplete: `email`,
																	onChange: (e: BaseSyntheticEvent) =>
																		setEmailAddress(e.target.value),
																}}
															/>
															<Checkbox
																{...{
																	name: `consent`,
																	checked: consent,
																	onChange: () => setConsent(!consent),
																	className: `text-light2 text-sm mb-4`,
																}}
															>
																<>
																	I understand and consent to the{` `}
																	<Link to={`/privacy-policy`} className={`underline`}>
																		Privacy Policy
																	</Link>
																	{` `}
																	and{` `}
																	<Link to={`/terms-of-service`} className={`underline`}>
																		Terms of Service
																	</Link>
																</>
															</Checkbox>
															<div className="flex w-full justify-between">
																<Button
																	text="Submit"
																	color={BTN_COLOR.MAGENTA}
																	hoverColor={BTN_COLOR.LIGHT}
																	innerBtnClassName={`!px-6 w-full`}
																	containerClassName={``}
																	clickHandler={() => {
																		if (consent) {
																			handleSubmitSignUp()
																		}
																	}}
																	disabled={!consent}
																/>
																<Button
																	text="Cancel"
																	color={BTN_COLOR.LIGHT}
																	hoverColor={BTN_COLOR.LIGHT}
																	innerBtnClassName={`!px-6 w-full`}
																	containerClassName={``}
																	clickHandler={() => {
																		setShowSignUp(false)
																	}}
																/>
															</div>
														</form>
													</>
												)}
												{signUpFormError && (
													<p className="text-center text-light2">
														An error has occurred with the sign-up. Please try again later.
													</p>
												)}
												{hasSignedUp && (
													<p className="text-center text-light2 text-2xl">
														You have successfully signed up
													</p>
												)}
											</div>
										</div>
									</div>
								</CSSTransition>
								{showHeader && (
									<>
										<div
											className={`mb-8 z-10 w-full mx-auto overflow-hidden relative top-left-corner-fade-in`}
										>
											<MediaItem
												gatsbyImageData={logo.gatsbyImageData}
												isImage={logo.isImage}
												format={logo.format}
												svgSrc={logo.url}
												className="z-10 fill-teal"
											/>
										</div>

										{/* <div className="flex justify-between items-center flex-wrap h-auto w-full mb-8 max-h-[36px]">
										{platforms.map((platform, i) => (
											<React.Fragment key={`charBladeMediaItem-${i + 1}`}>
												<MediaItem
													gatsbyImageData={platform.namedIcon.gatsbyImageData}
													isImage={platform.namedIcon.isImage}
													format={platform.namedIcon.format}
													svgSrc={platform.namedIcon.url}
													className="z-10 w-1/5 max-h-[36px] fill-light2"
													mediaItemClasses="w-full max-h-[36px]"
												/>
											</React.Fragment>
										))}
									</div> */}
										<div className="flex flex-col lg:flex-row w-full justify-center items-center  pt-2 pb-2">
											{buttons.map((button: IButtonProps, i: number) => (
												<React.Fragment key={`charBladeBtn-${i + 1}`}>
													<Button
														icon={button.buttonIcon}
														text={button.text}
														color={button.buttonColour}
														hoverColor={button.hoverColour}
														hasIcon={button.hasIcon}
														innerBtnClassName={`z-10 w-full `}
														containerClassName={` ${i + 1 === 1 ? `lg:mr-8` : ``}`}
														clickHandler={
															button.text.toLowerCase() === `register`
																? () => {
																		setShowSignUp(true)
																  }
																: () => {}
														}
														externalLink={button.externalLink}
														isExternal={button.isExternal}
														large={button.large}
													/>
												</React.Fragment>
											))}
										</div>
									</>
								)}
							</div>
						</div>
					</Blade>
				</BackgroundImage>

				<div className="diamonds-animation w-full h-1/2 md:h-full md:w-1/2 flex flex-nowrap flex-1 absolute overflow-visible z-30 top-0">
					{_characters.map((characterSet, index) => {
						const firstImageClasses = `absolute -left-[0%] -top-28 md:-left-16 md:-top-40 lg:-left-24 lg:-top-44 xl:-left-20 xl:-top-56 2xl:-left-16 2xl:-top-56 scale-100  origin-center`
						const secondImageClasses = `absolute left-[calc(50%-23.5%)] top-32 md:left-20 md:top-44 lg:left-24 lg:top-56 xl:left-36 2xl:left-48 scale-100 origin-center`
						const thirdImageClasses = `absolute right-[0%] -top-28  md:left-56 md:-top-40 lg:left-[18rem] lg:-top-44 xl:left-[23rem] xl:-top-56 2xl:left-[28rem] 2xl:-top-56 scale-100 origin-center`

						return (
							<React.Fragment key={index}>
								<CharacterImage
									gatsbyImageData={characterSet[0].artwork.gatsbyImageData}
									isImage={characterSet[0].artwork.isImage}
									format={characterSet[0].artwork.format}
									svgSrc={characterSet[0].artwork.url}
									className={`${firstImageClasses} transition-all duration-900 ease-out `}
									show={index === sliceIndex}
								/>
								<CharacterImage
									gatsbyImageData={characterSet[1].artwork.gatsbyImageData}
									isImage={characterSet[1].artwork.isImage}
									format={characterSet[1].artwork.format}
									svgSrc={characterSet[1].artwork.url}
									className={`${secondImageClasses} transition-all duration-900 delay-300 ease-out `}
									show={index === sliceIndex}
								/>
								<CharacterImage
									gatsbyImageData={characterSet[2].artwork.gatsbyImageData}
									isImage={characterSet[2].artwork.isImage}
									format={characterSet[2].artwork.format}
									svgSrc={characterSet[2].artwork.url}
									className={` ${thirdImageClasses} transition-all duration-900 delay-[600ms] ease-out `}
									show={index === sliceIndex}
								/>
							</React.Fragment>
						)
					})}
				</div>
				<div
					className="absolute -bottom-[4.5%] md:bottom-[4.5%] left-0 right-0 z-80 mx-auto flex flex-col items-center justify-center cursor-pointer group"
					onClick={scrollToTrailer}
				>
					<h4 className="left-0 right-0 mx-auto h-full text-center text-light2 transition-all duration-300 mb-2 font-bold text-2xl">
						WATCH THE GAMEPLAY TRAILER NOW
					</h4>
					<div
						className={`flex justify-center items-center bg-magenta group-hover:bg-teal rounded-full w-24 h-24 transition-all duration-300 `}
					>
						<SVG
							className={`w-8 h-8 fill-light2 transition-all duration-300 rotate-90`}
							src={videoPlayIcon}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

export default CharacterHeroBlade
