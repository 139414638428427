import React, { BaseSyntheticEvent, useEffect, useState } from 'react'
import Button from '../Button'
import MediaItem from '../MediaItem'
import { IoIosArrowDropdown, IoIosClose } from 'react-icons/io'

import './styles.scss'
import TextInput from '../FormFields/TextInput'
import Link from '../Link'

interface VideoBackgroundHeroWithSignupBladeProps {
	className: string
	backgroundVideo: {
		url: string
	}
	backgroundImageFallback: {
		gatsbyImageData: any
		url: string
	}
	logoLeft: {
		gatsbyImageData: any
		url: string
	}
	logoRight: {
		gatsbyImageData: any
		url: string
	}
	id: string
	title: string
	countdownTo: string
	countdownEndedButtons: {
		buttonColour: string
		buttonIcon: any
		externalLink: string
		hasIcon: boolean
		hoverColour: string
		id: string
		isExternal: boolean
		large: boolean
		link: string
		text: string
	}[]
	countdownEndedTitle: string
	showLogos: boolean
	showCountdown: boolean
}

const VideoBackgroundHeroWithSignupBlade: React.FC<
	VideoBackgroundHeroWithSignupBladeProps
> = ({
	className,
	backgroundVideo,
	backgroundImageFallback,
	title,
	countdownEndedButtons,
	countdownEndedTitle,
	countdownTo,
	logoLeft,
	logoRight,
	showLogos,
	showCountdown,
}) => {
	function downloadURI(uri: string, name: string) {
		const link = document.createElement(`a`)
		link.download = name
		link.href = uri
		document.body.appendChild(link)
		link.click()
		document.body.removeChild(link)
	}

	const [screenAspectRatio, setScreenAspectRatio] = useState(0)
	const [emailAddress, setEmailAddress] = useState(``)
	const [timeToEvent, setTimeToEvent] = useState(0)
	const [formError, setFormError] = useState(``)
	const [hasSignedUp, setHasSignedUp] = useState(false)

	const downloadUrl = `https://launcher.evercoreheroes.com/`

	const [urlParams, setUrlParams] = useState<URLSearchParams | null>(null)

	useEffect(() => {
		if (typeof window !== `undefined`) {
			const queryString = window.location.search
			const urlParams = new URLSearchParams(queryString)
			if (urlParams) {
				setUrlParams(urlParams)
			}
		}
	}, [])

	useEffect(() => {
		if (urlParams && urlParams.has(`otk_down`)) {
			const down = urlParams.get(`otk_down`)

			if (down === `1`) {
				setTimeout(() => {
					downloadURI(downloadUrl, `VelaGamesLauncherSetup.exe`)
				}, 1000)
			}
		}
	}, [downloadUrl, urlParams])

	const countdownDate = new Date(countdownTo)

	const handleResize = () => {
		console.log(16 / 9, window.innerWidth / window.innerHeight)
		setScreenAspectRatio(window.innerWidth / window.innerHeight)
	}

	useEffect(() => {
		handleResize()
		window.addEventListener(`resize`, handleResize, false)

		return () => window.removeEventListener(`resize`, handleResize)
	}, [])

	useEffect(() => {
		const interval = setInterval(() => {
			setTimeToEvent(countdownDate.getTime() - Date.now())
		}, 10)

		return () => clearInterval(interval)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	function handleFormSubmit(e: BaseSyntheticEvent) {
		e.preventDefault()
		submitEmail()
	}

	async function submitEmail() {
		const res = await fetch(`https://evercoreheroes.com/v1/signup`, {
			method: `POST`,
			body: JSON.stringify({
				email_address: emailAddress,
			}),
		})
		const result = await res.json()
		if (result.message === `OK`) {
			setHasSignedUp(true)
		} else {
			setFormError(
				`There was an error with your submission - please try again in a few minutes`
			)
		}
	}

	return (
		<div className={`${className} relative min-h-screen`}>
			<div className="z-0 absolute top-0 left-0 w-full h-full scale-[102%] blur-sm">
				<MediaItem
					className="w-full h-full object-cover"
					isImage={true}
					format="image"
					gatsbyImageData={backgroundImageFallback.gatsbyImageData}
					mediaItemClasses={`w-full h-full object-cover`}
					animateIn={`fade-in-bg-video`}
					animateStart={`opacity-0`}
					svgSrc={backgroundImageFallback.url}
				/>
			</div>
			<div
				className={`object-cover min-h-screen scale-[102%] z-10 blur-sm ${
					screenAspectRatio >= 16 / 9
						? `aspect-auto w-full h-full`
						: `aspect-video h-screen left-[50%] -translate-x-[50%] md:left-0 md:translate-x-0`
				}`}
			>
				<MediaItem
					className="w-full h-full block min-h-screen"
					format="video"
					thumbnailUrl={backgroundVideo.url}
					mediaItemClasses={`w-full h-full`}
					animateIn={`fade-in-bg-video`}
					animateStart={`opacity-0`}
				/>
			</div>
			<div className="bg-black5/20 z-20 absolute top-0 left-0 w-full h-full" />
			<div className="z-30 absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center gap-4 md:gap-16">
				{showCountdown && (
					<div className="md:absolute top-16 left-0 right-0 md:right-auto bg-black5 flex flex-col items-center justify-center px-6 py-4">
						<h4 className="text-xl md:text-3xl text-teal uppercase font-bold mb-2">
							EXCLUSIVE PREVIEW ENDS IN

							{/*
							{countdownDate
								.toLocaleDateString(`en-US`, {
									weekday: `long`,
									year: `numeric`,
									month: `long`,
									day: `numeric`,
								})
								.replace(/y,/g, `y`)}
							*/}
						</h4>
						{timeToEvent > 0 ? (
							<div className="flex flex-row items-start justify-evenly gap-2">
								<div className="flex flex-col items-center justify-center gap-2">
									<p className="text-5xl text-light1 font-bold">
										{(`0` + Math.floor(timeToEvent / (1000 * 60 * 60 * 24))).slice(-2)}
									</p>
									<p className="text-light1 font-bold uppercase">days</p>
								</div>
								<p className="text-5xl text-light1">:</p>
								<div className="flex flex-col items-center justify-center gap-2">
									<p className="text-5xl text-light1 font-bold">
										{(
											`0` +
											Math.floor((timeToEvent % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
										).slice(-2)}
									</p>
									<p className="text-light1 font-bold uppercase">hours</p>
								</div>
								<p className="text-5xl text-light1">:</p>
								<div className="flex flex-col items-center justify-center gap-2">
									<p className="text-5xl text-light1 font-bold">
										{(
											`0` + Math.floor((timeToEvent % (1000 * 60 * 60)) / (1000 * 60))
										).slice(-2)}
									</p>
									<p className="text-light1 font-bold uppercase">minutes</p>
								</div>
								<p className="text-5xl text-light1">:</p>
								<div className="flex flex-col items-center justify-center gap-2">
									<p className="text-5xl text-light1 font-bold">
										{(`0` + Math.floor((timeToEvent % (1000 * 60)) / 1000)).slice(-2)}
									</p>
									<p className="text-light1 font-bold uppercase">seconds</p>
								</div>
							</div>
						) : (
							<p className="text-2xl text-light1 font-bold">THIS EVENT IS NOW OVER</p>
						)}
					</div>
				)}
				{showLogos && (
					<div className="hidden md:flex flex-row items-center md:justify-evenly w-full max-w-4xl gap-2 md:gap-16 px-8">
						<MediaItem
							className="flex-1"
							isImage={true}
							format="image"
							gatsbyImageData={logoLeft.gatsbyImageData}
							mediaItemClasses={`w-full`}
							animateIn={`fade-in`}
							animateStart={`opacity-0`}
							svgSrc={logoLeft.url}
						/>
						<IoIosClose
							size={screenAspectRatio >= 16 / 9 ? 64 : 32}
							className="fill-light1"
						/>
						<MediaItem
							className="flex-1"
							isImage={true}
							format="image"
							gatsbyImageData={logoRight.gatsbyImageData}
							mediaItemClasses={`w-full`}
							animateIn={`fade-in`}
							animateStart={`opacity-0`}
							svgSrc={logoRight.url}
						/>
					</div>
				)}

				<div className="flex w-full max-w-5xl flex-col justify-center items-center mx-2 lg:mx-auto lg:mb-0 gap-4 after:bg-black5/60 after:absolute after:-z-10 after:left-1 after:top-1 after:right-1 after:bottom-1 video-header-bg-clip pt-8 px-8 lg:px-24 pb-8 relative border-t border-b border-light1">
					{urlParams &&
					urlParams.get(`otk_down`) &&
					urlParams.get(`otk_down`) === `1` ? (
						<>
							<IoIosArrowDropdown size={64} className="fill-teal" />
							<p className="text-center text-light1 text-xl md:text-2xl font-bold">
								Your download will start automatically in a few seconds. If not, click
								the download button.
							</p>
							<div className="flex flex-col items-center justify-center">
								<p className="text-center text-light1 text-lg font-semibold">
									Download the Launcher
								</p>
								<Button
									text={`Download`}
									color={`magenta`}
									hoverColor={`magenta`}
									innerBtnClassName={`text-lg`}
									clickHandler={() => {
										downloadURI(downloadUrl, `VelaGamesLauncherSetup.exe`)
									}}
								/>
							</div>
						</>
					) : (
						<>
							<h4 class="uppercase font-body tracking-wide text-2xl lg:text-3xl xl:text-4xl text-teal text-center">THANKS FOR PLAYING IN BETA</h4>
							<h1 class="uppercase tracking-wide text-5xl lg:text-6xl xl:text-7xl text-light1 font-display text-center">{title}</h1>
							{timeToEvent <= 0 ? (
								<div className="flex flex-col justify-center items-center max-w-6xl w-full mx-auto gap-6 p-4">
									<p className="text-center text-light1 text-lg">
										You can still sign up for the closed beta!
									</p>
									{countdownEndedButtons.map((button) => (
										<Button
											key={`heroButton-${button.id}`}
											text={button.text}
											color={button.buttonColour}
											hasIcon={button.hasIcon}
											icon={button.buttonIcon}
											innerBtnClassName={`z-10 w-full`}
											link={button.link}
											isExternal={button.isExternal}
											hoverColor={button.hoverColour}
											borderHoverColor={`light`}
											large={button.large}
											externalLink={button.externalLink}
										/>
									))}
								</div>
							) : (
								<>
									{hasSignedUp ? (
										<div className="flex justify-center items-center py-16">
											<p className="text-center text-light1 text-2xl">
												Thanks for signing up! You should receive an email shortly to confirm.
											</p>
										</div>
									) : (
										<form
											className="flex justify-center items-center flex-col gap-4"
											onSubmit={handleFormSubmit}
										>
											<TextInput
												{...{
													className: `bg-light-2 w-full`,
													name: `email_address`,
													type: `email`,
													placeholder: `Enter Email`,
													required: true,
													autoComplete: `email`,
													onChange: (e: BaseSyntheticEvent) =>
														setEmailAddress(e.target.value),
												}}
											/>

											<div>
												<Button
													text={`Sign Up`}
													color={`magenta`}
													innerBtnClassName={`z-10`}
													hoverColor={`light1`}
													borderHoverColor={`light`}
													large={false}
													clickHandler={submitEmail}
													containerClassName="mb-0"
												/>

											</div>

											<p className="mx-auto max-w-2xl text-light1">
												Signup and consent to receive news and updates for Evercore Heroes.
												You can opt out by clicking the unsubscribe link in any email. For
												details about how your personal data is handled, see the{` `}
												<Link to={`/privacy-policy`} className={`underline`}>
													Privacy Policy
												</Link>
												.
											</p>

											{formError && (
												<p className="text-center text-light2 ">{formError}</p>
											)}
										</form>
									)}
								</>
							)}
						</>
					)}

					<span className="flex flex-col w-2 h-full absolute -top-0 -left-0 z-10">
						<span className="flex-1 border-l border-light1" />
						<span className="h-[6.3rem] lg:h-20 w-2 relative">
							<span className="block w-14 lg:w-[3.7rem] h-px absolute top-0 left-0 transform rotate-[65deg] lg:rotate-[42deg] origin-top-left bg-light1 z-30" />
							<span className="block w-14 lg:w-[3.7rem] h-px absolute bottom-0 left-0 transform -rotate-[65deg] lg:-rotate-[42deg] origin-bottom-left bg-light1 z-30" />
						</span>
						<span className="flex-1 border-l border-light1" />
					</span>
					<span className="flex flex-col w-2 h-full absolute -top-0 -right-0 z-10">
						<span className="flex-1 border-r border-light1" />
						<span className="h-[6.3rem] lg:h-20 w-2 relative">
							<span className="block w-14 lg:w-[3.7rem] h-px absolute top-0 right-0 transform -rotate-[65deg] lg:-rotate-[42deg] origin-top-right bg-light1 z-30" />
							<span className="block w-14 lg:w-[3.7rem] h-px absolute bottom-0 right-0 transform rotate-[65deg] lg:rotate-[42deg] origin-bottom-right bg-light1 z-30" />
						</span>
						<span className="flex-1 border-r border-light1" />
					</span>
				</div>
			</div>
		</div>
	)
}

export default VideoBackgroundHeroWithSignupBlade
