import React, { useState, useEffect } from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import BackgroundImage from 'gatsby-background-image'
import SVG from 'react-inlinesvg'

import Blade from '../Blade'
import Button, { BTN_COLOR } from '../Button'

import honeycomb from '@/images/honeycomb-green.png'
import characterLock from '@/images/character-lock.svg'
import characterDiamond from '@/images/character-diamond.svg'
import arrowIcon from '@/images/arrow-light.svg'
import FileSaver from 'file-saver'

import './style.scss'
import Heading from '../Heading/Heading'

export interface ICharacter {
	node: {
		name: string
		description: string
		thumbnail: any // TODO: typing
		featuredImage: any // TODO: typing
	}
}

export interface ICharacterGridBladeProps {
	__typename: string
	id: string
	key: number
	allHeroes: boolean
	heroes: ICharacter[]
	className: string
}

const Character: React.FC<ICharacter> = ({
	_index,
	index,
	setIndex,
	status,
	thumbnail,
	featuredImage,
	downloadableCharacterCard,
	name,
}) => {
	const _locked = status !== `unlocked`

	useEffect(() => {
		if (typeof window !== `undefined`) {
			if (window.location.hash === `#all-heroes`) {
				if (typeof document !== `undefined`) {
					const signUpElement = document.getElementById(`heroes-grid`)
					signUpElement?.scrollIntoView({
						behavior: `smooth`,
						block: `center`,
					})
				}
			}
		}
	}, [])

	const downloadImage = (url: string) => {
		FileSaver.saveAs(url, `${name}-character-card.jpg`)
	}
	return (
		<div
			className={`${
				!_locked ? `group` : `pointer-events-none`
			} pt-[100%] relative cursor-pointer`}
			onMouseEnter={() => setIndex(_index)}
			onMouseLeave={() => setIndex(-1)}
			id="all-heroes"
		>
			<div className="w-full p-0 group-hover:w-[110%] xl:group-hover:w-[140%] h-full group-hover:h-[160%] xl:group-hover:h-[200%] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-0 group-hover:z-20 transition-all duration-300">
				<span className="block w-11/12 h-[92%] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 shadow-xl transition-all duration-700" />

				<div
					className={`character-grid-blade__thumbnail ${
						_index === index ? `active` : `inactive`
					} w-full h-full relative  transition-all duration-500`}
				>
					{!_locked ? (
						<GatsbyImage
							image={getImage(thumbnail)}
							className="w-full h-full absolute top-0 left-0"
						/>
					) : (
						<div className="flex items-center justify-center w-full h-full absolute top-0 left-0 bg-gradient-to-b from-black5 to-black3 text-center">
							<SVG
								className={`w-full h-auto`}
								src={characterDiamond}
								className="w-24 h-auto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
							/>

							<SVG
								className={`w-8 h-auto fill-light2 text-light3 group-hover:fill-black1 group-hover:opacity-100 transition-all duration-300`}
								src={characterLock}
							/>

							<span className="block w-[calc(100%-1.75rem)] h-[calc(100%-0.75rem)] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 border border-light1 border-opacity-20 " />
						</div>
					)}

					<div className="w-full h-[92%] absolute top-0 left-0 opacity-0 group-hover:opacity-100 invisible group-hover:visible transition-all duration-700 bg-light2">
						{!_locked ? (
							<GatsbyImage
								image={getImage(downloadableCharacterCard.gatsbyImageData)}
								className="w-full h-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 transition-all duration-700"
							/>
						) : null}

						<span className="block w-full h-1/2 absolute bottom-0 left-0 " />
					</div>

					{/* <h3 className="block w-full px-6 pb-6 absolute bottom-[10%] left-0 transform translate-y-full group-hover:translate-y-0 opacity-0 group-hover:opacity-100 invisible group-hover:visible font-display font-medium uppercase text-xl md:text-2xl lg:text-3xl text-black2 text-center transition-all duration-500 group-hover:delay-200">
						{name}
					</h3> */}
					<div
						onClick={() => downloadImage(downloadableCharacterCard?.url)}
						className=" z-90 absolute bottom-0 left-0 right-0 bg-teal h-[8%] w-full transform translate-y-full group-hover:translate-y-0 opacity-0 group-hover:opacity-100 invisible group-hover:visible text-light2 uppercase font-display flex justify-center items-center duration-700"
					>
						<SVG src={arrowIcon} className="w-6 h-6 fill-light2 rotate-180 mr-2" />
						Download
					</div>
				</div>

				<span className="-z-20 block w-[calc(100%-0.6rem)] bg-black3 group-hover:w-[calc(100%+0.6rem)] h-[calc(100%+0.6rem)] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 transition-all">
					<span className="flex flex-col w-0.5 h-full absolute top-0 left-0">
						<span className="flex-1 w-0.5 bg-light1" />
						<span class="h-9 group-hover:h-6 w-0.5 relative transition-all duration-500">
							<span class="block w-[1.35rem] group-hover:w-4 h-0.5 absolute top-0 group-hover:-top-px left-0.5 transform rotate-[120deg] group-hover:rotate-[56deg] origin-top-left bg-light1 z-40 transition-all duration-500" />
							<span class="block w-[1.35rem] group-hover:w-4 h-0.5 absolute bottom-0 group-hover:-bottom-px left-0.5 transform -rotate-[120deg] group-hover:-rotate-[56deg] origin-bottom-left bg-light1 z-40 transition-all duration-500" />
						</span>
						<span className="flex-1 w-0.5 bg-light1" />
					</span>

					<span className="flex flex w-full h-0.5 absolute top-0 right-0 bg-light1"></span>

					<span className="flex flex-col w-0.5 h-full absolute top-0 right-0">
						<span className="flex-1 w-0.5 bg-light1" />
						<span class="h-9 group-hover:h-6 w-0.5 relative transition-all duration-500">
							<span class="block w-[1.35rem] group-hover:w-4 h-0.5 absolute top-0 group-hover:-top-px right-0.5 transform -rotate-[120deg] group-hover:-rotate-[56deg] origin-top-right bg-light1 z-40 transition-all duration-500" />
							<span class="block w-[1.35rem] group-hover:w-4 h-0.5 absolute bottom-0 group-hover:-bottom-px right-0.5 transform rotate-[120deg] group-hover:rotate-[56deg] origin-bottom-right bg-light1 z-40 transition-all duration-500" />
						</span>
						<span className="flex-1 w-0.5 bg-light1" />
					</span>

					<span className="flex flex w-full h-0.5 absolute bottom-0 left-0 bg-light1"></span>
				</span>
			</div>
		</div>
	)
}

const CharacterGridBlade: React.FC<ICharacterGridBladeProps> = ({
	key,
	subheading,
	heading,
	allCharacters,
	showAllCharacters,
	characters,
	className,
}) => {
	const [index, setIndex] = useState<number>(0)
	const [showFirst, setShowFirst] = useState<number>(50)

	const sortedCharacters = allCharacters.sort(
		(a: { node: { status: string } }, b: { node: { status: string } }) => {
			if (a.node.status === `unlocked` && b.node.status === `locked`) return -1
			if (a.node.status === `locked` && b.node.status === `unlocked`) return 1
			return 0
		}
	)

	return (
		<div className="character-grid-blade md:pb-20 md:-mb-24 relative text-center">
			<div className="w-full h-full absolute top-0 left-0 bg-black3 clipped-character-grid z-30">
				<div className="character-grid-blade__bg absolute top-0 left-0 w-full h-full overflow-hidden z-40">
					<div className="character-grid-blade__fg w-full h-[150%] absolute bottom-0 left-1/2 transform -translate-x-1/2 z-40" />

					<img
						src={honeycomb}
						className="w-full max-w-[100rem] h-auto absolute top-32 lg:top-40 left-1/2 transform -translate-x-1/2 opacity-90 z-40"
					/>

					<div className="w-full h-full absolute top-0 left-0 bg-gradient-to-t from-black3 to-transparent z-40" />
				</div>
			</div>

			<div className="relative z-60">
				<Blade className="max-w-8xl mx-auto relative z-20">
					{subheading ? (
						<span className="block mx-auto text-lg md:text-2xl text-light1 uppercase italic">
							{subheading}
						</span>
					) : null}

					{heading ? (
						<Heading
							type={3}
							className="max-w-2xl mx-auto text-2xl md:text-3xl lg:text-4xl !text-light1 uppercase"
						>
							{heading}
						</Heading>
					) : null}

					<div className="mt-12 md:mt-24">
						<div className="flex flex-wrap -my-4 -mx-4">
							{sortedCharacters?.map((character: ICharacter, _index: number) => (
								<div
									key={_index}
									className="character-grid-blade__item w-full sm:w-1/2 lg:w-1/3 xl:w-1/5 py-4 px-4 relative"
									style={{
										backfaceVisibility: `hidden`,
										MozBackfaceVisibility: `hidden`,
										WebkitBackfaceVisibility: `hidden`,
									}}
								>
									<span className="clipped-diamond block w-2 h-3 absolute left-0 top-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-light1" />
									<Character {...{ index, setIndex, _index, ...character?.node }} />
								</div>
							))}
						</div>
					</div>

					{showFirst < allCharacters?.length ? (
						<div className="flex items-center justify-center">
							<Button
								color={BTN_COLOR.LIGHT}
								text="Load more"
								innerBtnClassName=" w-full md:w-fit"
								containerClassName="md:w-fit mx-auto mt-12 md:mt-20"
								clickHandler={() => setShowFirst(showFirst + 10)}
							/>
						</div>
					) : null}
				</Blade>
			</div>

			<span className="clip-side-triangle left block w-6 md:w-12 h-24 md:h-48 absolute top-0 left-0 transform -translate-y-1/2 bg-black5 z-40" />
		</div>
	)
}

export default CharacterGridBlade
