import mediaExpandIcon from '@/images/media-expand-icon.svg'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import React, { BaseSyntheticEvent, useEffect, useState } from 'react'
import Blade from '../Blade'
import Dropdown from '../FormFields/Dropdown'
import Heading from '../Heading/Heading'
import SVG from '../SVG'
import ItemBorder from './ItemBorder'
import diamondIcon from '@/images/video-diamond.svg'
import bgTriangle from '@/images/bg-triangle.svg'
import bgElement1 from '@/images/bg-elements-1.svg'
import bgElement2 from '@/images/bg-elements-2.svg'
import bgElement3 from '@/images/bg-elements-3.svg'
import bgElement4 from '@/images/bg-elements-4.svg'
import bgElement5 from '@/images/bg-elements-5.svg'
import FilterCategoryDropdown from './FilterCategoryDropdown.tsx'
import { useLightboxState } from '@/context'
import Button, { BTN_COLOR } from '../Button'
import { useInView } from 'react-intersection-observer'

interface IMediaBladeProps {
	header: string
	subHeader: string
	filterCategories: {
		name: string
		dropdownBackgroundImage: IGatsbyImageData
		hasDarkText: boolean
	}[]
	mediaCollection: {
		isVideo: boolean
		category: {
			name: string
		}
		image: {
			gatsbyImageData: IGatsbyImageData
			isImage: boolean
			url: string
			format: string
		}
		video: {
			url: string
			width: any
			title: string
			thumbnailUrl: string
			height: any
		}
	}[]
}

const MediaBlade: React.FC<IMediaBladeProps> = ({ ...props }) => {
	const { mediaCollection, header, subHeader, filterCategories } = props
	const [category, setCategory] = useState<string>(``)
	const [visibleCategories, setVisibleCategories] = useState<any>([])
	const [showFirst, setShowFirst] = useState<number>(6)
	const {
		setLightboxImages,
		setLightboxOpen,
		setLightboxVideo,
		setActiveLightboxImage,
	} = useLightboxState()
	const { ref, inView, entry } = useInView({
		/* Optional options */
		threshold: 0.3,
		triggerOnce: true,
	})
	const handleCategoryFilter = (e: string) => {
		if (e.toLowerCase() === `all`) {
			setCategory(``)
		} else {
			setCategory(e)
		}
	}

	useEffect(() => {
		if (category.length > 0) {
			const filteredCategories = mediaCollection.filter(
				(mediaItem) =>
					mediaItem.category.name.toLowerCase() === category.toLowerCase()
			)
			setVisibleCategories([...filteredCategories])
		} else {
			setVisibleCategories([...mediaCollection])
		}
	}, [category])

	const handleLightbox = (asset, isVideo) => {
		setActiveLightboxImage(0)
		isVideo ? setLightboxVideo(asset) : setLightboxImages(asset)
		setLightboxOpen(true)
	}

	return (
		<Blade className="relative min-h-[40rem] md:-top-36 md:pt-60 bg-light1">
			<SVG
				src={bgTriangle}
				className="absolute w-full -top-28 md:top-auto left-0 pointer-events-none  md:-bottom-40 z-0"
			/>
			{/* 
			<SVG
				src={bgElement1}
				className="absolute -left-0 bottom-[4rem] w-10 fill-black3 "
			/>
			<SVG
				src={bgElement5}
				className="absolute left-[-0.72rem] top-[18rem] w-4 rotate-[30deg]"
			/> */}
			<div className="absolute -bottom-36 left-0 w-full h-[calc(100%+9rem)] media-gradient pointer-events-none"></div>
			<div className="w-full h-full" ref={ref}>
				<div className="flex flex-col md:flex-row justify-between items-center mb-12">
					<div className="h-full mr-8 z-10">
						<Heading
							className={`mb-4 text-center md:text-left ${
								inView ? `top-left-corner-fade-in` : `opacity-0`
							}`}
						>
							{header}
						</Heading>
						<Heading
							className={`text-center md:text-left mb-8 md:mb-0 ${
								inView ? `top-left-corner-fade-in-slow` : `opacity-0`
							}`}
							type={5}
						>
							{subHeader}
						</Heading>
					</div>
					<FilterCategoryDropdown
						options={filterCategories.map((category: any) => ({
							value: category.name,
							label: category.name,
							bgImage: category.dropdownBackgroundImage,
							hasDarkText: category.hasDarkText,
						}))}
						value={category}
						onChange={handleCategoryFilter}
						className="z-10"
					/>
				</div>
				<div>
					<div className="grid gap-10 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
						{visibleCategories.map((mediaItem, i) => {
							if (showFirst > i) {
								return (
									<div className={`${inView ? `media-grid-item` : `opacity-0`}`}>
										{mediaItem.isExternalVideo ? (
											<div
												key={i}
												className={`w-full overflow-visible cursor-pointer group hover:scale-105 transition-all duration-300`}
												onClick={() => {
													handleLightbox(mediaItem.externalVideo, true)
												}}
											>
												<ItemBorder className="relative md:mx-auto ">
													<img
														src={mediaItem.externalVideo.thumbnailUrl}
														className="w-full aspect-video object-cover z-20"
													/>
													<div className="bg-black3 group-hover:bg-light2 opacity-90 rounded-full h-16 w-16 absolute top-[calc(50%-2rem)] left-[calc(50%-2rem)] flex justify-center items-center transition-all duration-300">
														<SVG
															src={mediaExpandIcon}
															className="h-1/2 w-1/2 fill-light2 text-light2 group-hover:text-black5 group-hover:fill-black5  transition-all duration-300"
														/>
													</div>
												</ItemBorder>
											</div>
										) : mediaItem.isVideo ? (
											<div
												key={i}
												className={`w-full overflow-visible cursor-pointer group hover:scale-105 transition-all duration-300`}
												onClick={() => {
													handleLightbox(mediaItem.video, true)
												}}
											>
												<ItemBorder className="relative md:mx-auto ">
													<img
														src={mediaItem.video.video.thumbnailUrl}
														className="w-full h-44 2xs:h-56 xs:h-72 sm:h-56 xl:h-72 object-cover z-20"
													/>
													<div className="bg-black3 group-hover:bg-light2 opacity-90 rounded-full h-16 w-16 absolute top-[calc(50%-2rem)] left-[calc(50%-2rem)] flex justify-center items-center transition-all duration-300">
														<SVG
															src={mediaExpandIcon}
															className="h-1/2 w-1/2 fill-light2 text-light2 group-hover:text-black5 group-hover:fill-black5  transition-all duration-300"
														/>
													</div>
												</ItemBorder>
											</div>
										) : (
											<div
												key={i}
												className={`w-full overflow-visible cursor-pointer group hover:scale-105 transition-all duration-300`}
												onClick={() => {
													handleLightbox([mediaItem.image.gatsbyImageData], false)
												}}
											>
												<ItemBorder className="relative w-full h-full object-cover">
													<img
														src={mediaItem.image.url}
														className="w-full h-44 2xs:h-56 xs:h-72 sm:h-56 xl:h-72 2xl:h-[22rem] object-cover"
													/>
													<div className="bg-black3 group-hover:bg-light2 opacity-90 rounded-full h-16 w-16 absolute top-[calc(50%-2rem)] left-[calc(50%-2rem)] flex justify-center items-center transition-all duration-300">
														<SVG
															src={mediaExpandIcon}
															className="h-1/2 w-1/2 fill-light2 text-light2 group-hover:text-black5 group-hover:fill-black5 transition-all duration-300"
														/>
													</div>
												</ItemBorder>
											</div>
										)}
									</div>
								)
							} else {
								return null
							}
						})}
					</div>
					{showFirst < visibleCategories?.length ? (
						<Button
							color={BTN_COLOR.MAGENTA}
							hoverColor={BTN_COLOR.DARK}
							text="Load more"
							innerBtnClassName=" !w-full md:!w-fit"
							containerClassName="!w-full md:!w-fit mx-auto mt-12 md:mt-20"
							clickHandler={() => setShowFirst(showFirst + 6)}
						/>
					) : null}
				</div>
			</div>
			<div className="absolute -bottom-36 left-0 w-full h-36 bg-light1 -z-20"></div>
		</Blade>
	)
}

export default MediaBlade
