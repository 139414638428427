import { CONFIG_COLORS } from '@/types'
import React, { useEffect } from 'react'
import Button from '../Button'
import Heading from '../Heading/Heading'
import MediaItem from '../MediaItem'

import videoPlayIcon from '@/images/video-play-icon.svg'

import './styles.scss'
import SVG from '../SVG'

interface VideoBackgroundHeroBladeProps {
	className: string
	backgroundVideo: {
		url: string
	}
	backgroundImageFallback: {
		gatsbyImageData: any
		url: string
	}
	id: string
	title: string
	titleTagline: string
	buttons: {
		buttonColour: string
		buttonIcon: any
		externalLink: string
		hasIcon: boolean
		hoverColour: string
		id: string
		isExternal: boolean
		large: boolean
		link: string
		text: string
	}[]
}

const VideoBackgroundHeroBlade: React.FC<VideoBackgroundHeroBladeProps> = ({
	className,
	backgroundVideo,
	backgroundImageFallback,
	title,
	titleTagline,
	buttons,
}) => {
	const [screenAspectRatio, setScreenAspectRatio] = React.useState(0)

	const scrollToTrailer = () => {
		if (typeof window !== `undefined`) {
			if (typeof document !== `undefined`) {
				const signUpElement = document.getElementById(`text-media-blade`)
				signUpElement?.scrollIntoView({
					behavior: `smooth`,
					block: `start`,
				})
			}
		}
	}

	const handleResize = () => {
		console.log(16 / 9, window.innerWidth / window.innerHeight)
		setScreenAspectRatio(window.innerWidth / window.innerHeight)
	}

	useEffect(() => {
		handleResize()
		window.addEventListener(`resize`, handleResize, false)

		return () => window.removeEventListener(`resize`, handleResize)
	}, [])

	return (
		<div className={`${className} relative min-h-screen`}>
			<div className="z-0 absolute top-0 left-0 w-full h-full scale-[102%] blur-sm">
				<MediaItem
					className="w-full h-full object-cover"
					isImage={true}
					format="image"
					gatsbyImageData={backgroundImageFallback.gatsbyImageData}
					mediaItemClasses={`w-full h-full object-cover`}
					animateIn={`fade-in-bg-video`}
					animateStart={`opacity-0`}
					svgSrc={backgroundImageFallback.url}
				/>
			</div>
			<div
				className={`object-cover min-h-screen scale-[102%] z-10 blur-sm ${
					screenAspectRatio >= 16 / 9
						? `aspect-auto w-full h-full`
						: `aspect-video h-screen left-[50%] -translate-x-[50%] md:left-0 md:translate-x-0`
				}`}
			>
				<MediaItem
					className="w-full h-full block min-h-screen"
					format="video"
					thumbnailUrl={backgroundVideo.url}
					mediaItemClasses={`w-full h-full`}
					animateIn={`fade-in-bg-video`}
					animateStart={`opacity-0`}
				/>
			</div>
			<div className="bg-black5/20 z-20 absolute top-0 left-0 w-full h-full" />
			<div className="z-30 absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center">
				<div className="flex flex-col justify-center items-center mx-2 lg:mx-auto mb-8 lg:mb-0 gap-2 after:bg-black5/60 after:absolute after:-z-10 after:left-1 after:top-1 after:right-1 after:bottom-1 video-header-bg-clip pt-8 px-8 lg:px-24 pb-4 relative border-t border-b border-light1">
					<Heading className="text-center" type={4} color={CONFIG_COLORS.TEAL}>
						{titleTagline}
					</Heading>
					<Heading className="text-center" type={1} color={CONFIG_COLORS.LIGHT_1}>
						{title}
					</Heading>
					{buttons.length > 0 && (
						<div className="flex flex-col justify-center items-center max-w-6xl w-full mx-auto gap-8 mt-2">
							{buttons.map((button) => (
								<Button
									key={`heroButton-${button.id}`}
									text={button.text}
									color={button.buttonColour}
									hasIcon={button.hasIcon}
									icon={button.buttonIcon}
									innerBtnClassName={`z-10 w-full`}
									link={button.link}
									isExternal={button.isExternal}
									hoverColor={button.hoverColour}
									borderHoverColor={`light`}
									large={button.large}
									externalLink={button.externalLink}
								/>
							))}
						</div>
					)}
					<span className="flex flex-col w-2 h-full absolute -top-0 -left-0 z-10">
						<span className="flex-1 border-l border-light1" />
						<span className="h-[6.3rem] lg:h-20 w-2 relative">
							<span className="block w-14 lg:w-[3.7rem] h-px absolute top-0 left-0 transform rotate-[65deg] lg:rotate-[42deg] origin-top-left bg-light1 z-30" />
							<span className="block w-14 lg:w-[3.7rem] h-px absolute bottom-0 left-0 transform -rotate-[65deg] lg:-rotate-[42deg] origin-bottom-left bg-light1 z-30" />
						</span>
						<span className="flex-1 border-l border-light1" />
					</span>
					<span className="flex flex-col w-2 h-full absolute -top-0 -right-0 z-10">
						<span className="flex-1 border-r border-light1" />
						<span className="h-[6.3rem] lg:h-20 w-2 relative">
							<span className="block w-14 lg:w-[3.7rem] h-px absolute top-0 right-0 transform -rotate-[65deg] lg:-rotate-[42deg] origin-top-right bg-light1 z-30" />
							<span className="block w-14 lg:w-[3.7rem] h-px absolute bottom-0 right-0 transform rotate-[65deg] lg:rotate-[42deg] origin-bottom-right bg-light1 z-30" />
						</span>
						<span className="flex-1 border-r border-light1" />
					</span>
				</div>
				<div
					className="absolute bottom-4 lg:bottom-16 z-80 flex flex-col items-center justify-center cursor-pointer group bottom-left-corner-fade-in"
					onClick={scrollToTrailer}
				>
					<h4 className="left-0 right-0 mx-auto h-full text-center text-light2 transition-all duration-300 mb-2 font-bold text-lg lg:text-2xl">
						WATCH THE GAMEPLAY TRAILER NOW
					</h4>
					<div
						className={`flex justify-center items-center bg-magenta group-hover:bg-teal rounded-full w-20 h-20 lg:w-24 lg:h-24 transition-all duration-300 `}
					>
						<SVG
							className={`w-8 h-8 fill-light2 transition-all duration-300 rotate-90`}
							src={videoPlayIcon}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

export default VideoBackgroundHeroBlade
