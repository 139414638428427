import { BaseComponentProps } from '@/types'
import { FC } from 'react'

import { graphql, Script } from 'gatsby'
import Blades from '@/components/Blades'
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import { ContextProvider } from '@/context'
import Lightbox from '@/components/Lightbox'
import Meta from '@/hoc/Meta'
import CookieBanner from '@/globals/CookieBanner'

interface IPageProps extends BaseComponentProps {
	// Base Component Props holds only children - you can either extend it,
	// Or add more props in @/types/index.ts
	data: {
		page: {
			__typename: string
			id: string
			pageSlug: string
			pageName: string
			blades: any[]
		}
	}
	pageContext: any
	location: any
}

const Page: FC<IPageProps> = ({ pageContext, data, location }) => {
	const page = data?.page
	const blades = page?.blades
	return (
		<main>
			{page.pageSlug === `home` && (
				<>
					<img
						src="https://secure.adnxs.com/seg?add=34274186&t=2"
						width="1"
						height="1"
						style={{ zIndex: -1, position: `absolute`, top: 0, left: 0 }}
					/>
				</>
			)}

			<Script>
				{`(function(G,m,s,g,h,t){G.gsght=G.gsght||function(){
				(G.gsght.q=G.gsght.q||[]).push(arguments)};G.gsght.q=[],h=m.createElement(s),
				t=m.getElementsByTagName(s)[0];h.async=1;h.src=g;t.parentNode.insertBefore(h,t)
				})(window,document,'script','https://cdn.gsght.com/sdk.js');

				gsght('init', 'GS-101278');
				gsght('set', 'ga_enabled', true)
				gsght('send')`}
			</Script>
			<ContextProvider>
				<Meta {...{ seo: pageContext?.node?.seo }} />
				<Header locale={pageContext.locale} pathname={location.pathname} />
				<Lightbox />
				<Blades {...{ blades, allCharacters: pageContext?.allCharacters }} />
				<Footer locale={pageContext.locale} pathname={location.pathname} />
				<CookieBanner />
			</ContextProvider>
		</main>
	)
}

export default Page

export const query = graphql`
	query getPage($id: String) {
		page: datoCmsPage(id: { eq: $id }) {
			__typename
			id
			blades {
				__typename
				... on DatoCmsCharacterHeroBlade {
					...CharacterHeroBlade
				}
				... on DatoCmsTextMediaBlade {
					...TextMediaBlade
				}
				... on DatoCmsMediaBlade {
					...MediaBlade
				}
				... on DatoCmsSignUpFormBlade {
					...SignUpFormBlade
				}
				... on DatoCmsRosterCarouselBlade {
					...RosterCarouselBlade
				}
				... on DatoCmsSimpleHeroBlade {
					...SimpleHeroBlade
				}
				... on DatoCmsGameplayCarouselBlade {
					...GameplayCarouselBlade
				}
				... on DatoCmsCharacterGridBlade {
					...CharacterGridBlade
				}
				... on DatoCmsFullscreenCarouselBlade {
					...FullscreenCarouselBlade
				}
				... on DatoCmsFaqsBlade {
					...FaqsBlade
				}
				... on DatoCmsBossCarouselBlade {
					...BossCarouselBlade
				}
				... on DatoCmsVideoBackgroundHeroBlade {
					...VideoBackgroundHeroBlade
				}
				... on DatoCmsFullScreenLandingPageBlade {
					...FullScreenLandingPageBlade
				}
				... on DatoCmsVideoBackgroundHeroWithSignupBlade {
					...VideoBackgroundHeroWithSignupBlade
				}
				... on DatoCmsArticlePageBlade {
					...ArticlePageBlade
				}
				... on DatoCmsLoreSectionBlade {
					...LoreSectionBlade
				}
			}

			seo {
				title
				twitterCard
				description
				image {
					url
				}
			}
		}
	}
`
