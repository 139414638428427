import React, { useEffect, useState } from 'react'

const useBreakpoint = () => {
	const [breakpoint, setBreakpoint] = useState<number | null>(null)

	const resize = () => {
		if (typeof window !== `undefined`) {
			setBreakpoint(window.innerWidth)
		}
	}

	useEffect(() => {
		if (typeof window !== `undefined`) {
			window.addEventListener(`resize`, resize)
			setBreakpoint(window.innerWidth)
		}
		return () => {
			if (typeof window !== `undefined`) {
				window.removeEventListener(`resize`, resize)
			}
		}
	}, [])

	if (breakpoint === null) {
		return null
	}

	if (breakpoint < 640) {
		return { text: `xs`, number: breakpoint }
	} else if (breakpoint < 768) {
		return { text: `sm`, number: breakpoint }
	} else if (breakpoint < 1024) {
		return { text: `md`, number: breakpoint }
	} else if (breakpoint < 1280) {
		return { text: `lg`, number: breakpoint }
	} else if (breakpoint < 1536) {
		return { text: `xl`, number: breakpoint }
	} else {
		return { text: `2xl`, number: breakpoint }
	}
}

export default useBreakpoint
