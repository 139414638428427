import { fetchCustomFields, putAddContacts } from '@/api/add-contacts'
import useBreakpoint from '@/hooks/useBreakpoint'
import diamondIcon from '@/images/video-diamond.svg'
import { CONFIG_COLORS } from '@/types'
import BackgroundImage from 'gatsby-background-image'
import { getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import React, { BaseSyntheticEvent, useEffect, useRef, useState } from 'react'
import Blade from '../Blade'
import Button, { BTN_COLOR } from '../Button'
import Divider from '../Divider'
import Checkbox from '../FormFields/Checkbox'
import TextInput from '../FormFields/TextInput'
import Heading from '../Heading/Heading'
import SVG from '../SVG'
import { CSSTransition } from 'react-transition-group'
import Lottie from 'react-lottie'
import * as hexBgTeal from '@/images/lotties/hex-bg-teal.json'
import { useInView } from 'react-intersection-observer'
import Link from '../Link'

interface ISignUpFormBladeProps {
	heading: string
	backgroundImage: {
		gatsbyImageData: IGatsbyImageData
		title: string
	}
}

const SignUpFormBlade: React.FC<ISignUpFormBladeProps> = ({ ...props }) => {
	const { ref, inView, entry } = useInView({
		/* Optional options */
		threshold: 0.3,
		triggerOnce: true,
	})
	const { heading, backgroundImage } = props
	const formRef = useRef(null)
	useEffect(() => {
		if (typeof window !== `undefined`) {
			if (window.location.hash === `#signupform`) {
				if (typeof document !== `undefined`) {
					const signUpElement = document.getElementById(`sign-up-form-blade`)
					signUpElement?.scrollIntoView({
						behavior: `smooth`,
						block: `start`,
					})
				}
			}
		}
	}, [])
	// Form Field State
	const [emailAddress, setEmailAddress] = useState<string>(``)
	const [channelName, setChannelName] = useState<string>(``)
	const [subscriberNumber, setSubscriberNumber] = useState<string>(``)
	const [channelURL, setChannelURL] = useState<string>(``)
	const [streamingPlatform, setStreamingPlatform] = useState<string>(``)
	const [consent, setConsent] = useState<boolean>(false)
	const [isContentCreator, setIsContentCreator] = useState<boolean>(false)

	// Other State
	const [hasSignedUp, setHasSignedUp] = useState<boolean>(false)
	const [showSignUp, setShowSignUp] = useState<boolean>(true)
	const [signUpFormError, setSignUpFormError] = useState<any>(null)
	const background = getImage(backgroundImage.gatsbyImageData)
	const bg = convertToBgImage(background)
	const breakpoint = useBreakpoint()

	const handleSubmitSignUp = async () => {
		setHasSignedUp(false)
		setSignUpFormError(null)

		const reqBody = isContentCreator && {
			channel_name: channelName,
			subscriber_number: subscriberNumber,
			channel_url: channelURL,
			streaming_platform: streamingPlatform,
		}

		const params = reqBody && new URLSearchParams(reqBody)
		const res = await fetch(
			`https://evercoreheroes.com/v1/signup${reqBody ? `?${params}` : ``}`,
			{
				method: `POST`,
				body: JSON.stringify({
					email_address: emailAddress,
				}),
			}
		)
		const result = await res.json()
		if (result.message === `OK`) {
			setHasSignedUp(true)
		} else {
			setSignUpFormError(true)
		}
	}

	const hexBgLottieOptions = {
		loop: true,
		autoplay: true,
		animationData: hexBgTeal,
		rendererSettings: {
			preserveAspectRatio: `xMidYMid slice`,
		},
	}

	return (
		<div className="bg-black3 relative" id="sign-up-form-blade" ref={ref}>
			<div className="">
				<BackgroundImage
					{...bg}
					className="w-full h-full"
					style={{
						// Defaults are overwrite-able by setting one or each of the following:
						backgroundSize: ``,
						backgroundPosition: ``,
						backgroundRepeat: ``,
					}}
				>
					<Blade className="sign-up-gradient py-0 pt-20  pb-40 md:pb-40">
						{/* <div className="w-full absolute top-20 lg:top-20 left-1/2 transform -translate-x-1/2 opacity-50 z-0">
						<Lottie options={hexBgLottieOptions} height={400} width={`100%`} />
					</div>
					<div className="w-full h-auto absolute top-20 lg:top-20 left-1/2 transform -translate-x-1/2 opacity-50 translate-y-[100%] z-0">
						<Lottie options={hexBgLottieOptions} height={400} width={`100%`} />
					</div> */}
						<form className="z-20">
							<Heading
								type={3}
								className={`text-center mb-4 ${
									inView ? `top-left-corner-fade-in` : `opacity-0`
								}`}
								color={CONFIG_COLORS.LIGHT_2}
							>
								{heading}
							</Heading>
							<h6
								className={`text-center mb-14 text-2xl font-light text-light2 ${
									inView ? `top-left-corner-fade-in` : `opacity-0`
								}`}
							>
								By signing up you&apos;ll receive the latest news and insider offers -
								don&apos;t miss out!
							</h6>
							<div className="relative mx-auto flex justify-center w-full md:max-w-[740px]">
								<>
									<SVG
										src={diamondIcon}
										className={`${
											inView ? `delayed-fade-in-opacity` : `opacity-0`
										}  fill-light2 h-3 -left-1 top-[8.5rem] absolute hidden md:block transition-all duration-300 ${
											showSignUp && inView ? `opacity-100` : `opacity-0`
										}`}
									/>
									<SVG
										src={diamondIcon}
										className={`${
											inView ? `delayed-fade-in-opacity` : `opacity-0`
										} fill-light2 h-3 -right-1 top-[8.5rem] absolute hidden md:block transition-all duration-300 ${
											showSignUp && inView ? `opacity-100` : `opacity-0`
										}`}
									/>
									<SVG
										src={diamondIcon}
										className={`${
											inView ? `delayed-fade-in-opacity` : `opacity-0`
										} fill-light2 h-3 -left-1 ${
											isContentCreator ? `top-[30rem]` : `top-[18.5rem]`
										} absolute hidden md:block transition-all duration-300 ${
											showSignUp && inView ? `opacity-100` : `opacity-0`
										}`}
									/>
									<SVG
										src={diamondIcon}
										className={`${
											inView ? `delayed-fade-in-opacity` : `opacity-0`
										} fill-light2 h-3 -right-1 ${
											isContentCreator ? `top-[30rem]` : `top-[18.5rem]`
										} absolute hidden md:block transition-all duration-300 ${
											showSignUp && inView ? `opacity-100` : `opacity-0`
										}`}
									/>
								</>

								<div
									className={`${
										inView ? `delayed-fade-in-opacity` : `opacity-0`
									} w-full md:max-w-[740px] md:border md:border-light3 px-0 md:px-12 p-12 transition-all duration-300 ${
										hasSignedUp
											? ``
											: isContentCreator
											? `clipped-expanded-sign-up`
											: `clipped-sign-up`
									} `}
								>
									{showSignUp && (
										<div>
											<div className="flex justify-center w-full">
												<TextInput
													{...{
														className: `bg-light-2 w-full`,
														name: `email_address`,
														type: `email`,
														placeholder: `Enter Email`,
														required: true,
														autoComplete: `email`,
														onChange: (e: BaseSyntheticEvent) =>
															setEmailAddress(e.target.value),
													}}
												/>
											</div>
											<Divider />
											<div className="flex flex-col">
												<Checkbox
													{...{
														name: `boolContentCreator`,
														checked: isContentCreator,
														onChange: () => setIsContentCreator(!isContentCreator),
														className: `text-light2 uppercase italic my-4 ml-3 sm:text-2xl`,
														checkboxRight: true,
														isToggle: true,
														separateItems: breakpoint && breakpoint.number <= 768,
													}}
												>
													Are you a content creator?
												</Checkbox>
												{isContentCreator && (
													<div>
														<div className="flex-col flex md:flex-row md:justify-between">
															<TextInput
																{...{
																	className: `my-4 md:mr-6`,
																	name: `channel_name`,
																	placeholder: `Channel Name`,
																	onChange: (e: BaseSyntheticEvent) =>
																		setChannelName(e.target.value),
																}}
															/>
															<TextInput
																{...{
																	className: `my-4`,

																	name: `channel_url`,
																	placeholder: `Channel URL`,
																	onChange: (e: BaseSyntheticEvent) =>
																		setChannelURL(e.target.value),
																}}
															/>
														</div>
														<div className="flex-col flex md:flex-row md:justify-between">
															<TextInput
																{...{
																	className: `my-4 md:mr-6`,

																	name: `streaming_platform`,
																	placeholder: `Streaming Platform`,
																	onChange: (e: BaseSyntheticEvent) =>
																		setStreamingPlatform(e.target.value),
																}}
															/>
															<TextInput
																{...{
																	className: `my-4`,

																	name: `subscribers`,
																	placeholder: `Subscribers`,
																	onChange: (e: BaseSyntheticEvent) =>
																		setSubscriberNumber(e.target.value),
																	type: `number`,
																}}
															/>
														</div>
													</div>
												)}
											</div>
											<Divider />
											<div className="flex flex-col-reverse md:flex-col justify-center items-center">
												<Checkbox
													{...{
														name: `consent`,
														checked: consent,
														onChange: () => setConsent(!consent),
														className: `text-light2 text-light my-8 md:mt-0`,
													}}
												>
													Click to consent to receive news and updates for Evercore Heroes.
													You can opt out by clicking the unsubscribe link in any email. For
													details about how your personal data is handled, see the{` `}
													<Link to={`/privacy-policy`} className={`underline`}>
														Privacy Policy
													</Link>
													.
												</Checkbox>
												<Button
													color={BTN_COLOR.MAGENTA}
													hoverColor={BTN_COLOR.LIGHT}
													text="Submit"
													innerBtnClassName=" w-full md:w-fit"
													containerClassName="md:w-fit mx-auto"
													clickHandler={() => {
														if (consent) {
															handleSubmitSignUp()
														}
													}}
													disabled={!consent}
												/>
												{signUpFormError && (
													<p className="text-center text-light2 ">
														An error has occurred with the sign-up. Please try again later.
													</p>
												)}
											</div>
										</div>
									)}

									<CSSTransition
										in={hasSignedUp}
										timeout={300}
										classNames="fade"
										unmountOnExit
										nodeRef={formRef}
										onEnter={() => setShowSignUp(false)}
										onExited={() => setShowSignUp(true)}
									>
										<p className="text-light2 mx-auto text-center text-xl">
											Please check your email, to confirm registration.
										</p>
									</CSSTransition>
								</div>
							</div>
						</form>
					</Blade>
				</BackgroundImage>
			</div>

			<div className="w-full h-full">
				<div className="h-[1px] w-[calc(50%-0.75rem)] md:w-[calc(50%-6.75rem)] bg-black1 absolute top-12 left-0 md:left-24 mb-6 fade-in-from-right" />
				<div className="h-[1px] w-[calc(50%-0.75rem)] md:w-[calc(50%-6.75rem)] bg-black1 absolute top-12 right-0 md:right-24 mb-6 fade-in-from-left" />
				<SVG
					src={diamondIcon}
					className="fill-black1 h-2 absolute left-[calc(50%-0.175rem)] top-11 fade-in-opacity"
				/>
			</div>

			<SVG
				src={diamondIcon}
				className={`h-32 w-20 absolute -bottom-16 -left-10 z-40 fill-teal`}
			/>
		</div>
	)
}

export default SignUpFormBlade
