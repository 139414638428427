import React from 'react'
import Heading from '../Heading/Heading'
import LoreBox from '../LoreBox'

import bgElement1 from '@/images/bg-elements-1.svg'
import bgElement2 from '@/images/bg-elements-2.svg'
import bgElement3 from '@/images/bg-elements-3.svg'
import bgElement4 from '@/images/bg-elements-4.svg'
import bgElement5 from '@/images/bg-elements-5.svg'
import SVG from '../SVG'
import { GatsbyImage } from 'gatsby-plugin-image'
import { CONFIG_COLORS } from '@/types'

export interface ILoreSectionProps {
	__typename: string
	id: string
	key: number
	className: string
	title: string
	description: string
	items: {
		__typename: string
		id: string
		title: string
		slug: string
		coverImage: {
			url: string
			gatsbyImageData: any
			alt: string
		}
		category: {
			title: string
			slug: string
		}
		publishedAt: string
	}[]
	backgroundCharacter: {
		featuredImage: {
			url: string
			gatsbyImageData: any
			alt: string
		}
	}
}

const LoreSection: React.FC<ILoreSectionProps> = ({
	className,
	title,
	description,
	items,
	backgroundCharacter,
}) => {
	console.log(backgroundCharacter)

	return (
		<section className={`${className} bg-light2`}>
			<div className="absolute w-full h-full top-0 left-0 z-10">
				<div className="relative w-full h-full">
					<SVG
						src={bgElement1}
						className="absolute left-10 top-[16rem] w-10 fill-black3 delayed-fade-in-opacity"
					/>
					<SVG
						src={bgElement2}
						className="hidden md:block absolute left-72 top-72 w-5 fill-black3 delayed-fade-in-opacity"
					/>
					<div className="absolute right-0 top-0 teal-triangle-bg">
						<SVG
							src={bgElement4}
							className="hidden md:block bg-black3 h-2 w-4 absolute top-40 clipped-parallelogram-alt left-[5.1rem] delayed-fade-in-opacity"
						/>
						<SVG
							src={bgElement4}
							className="hidden md:block bg-black3 h-2 w-4 absolute top-80 clipped-parallelogram-alt left-[10.8rem] delayed-fade-in-opacity"
						/>
					</div>
					<div className="w-[1px] h-[110%] absolute bg-black1 left-[60%] top-0 -rotate-[30deg] delayed-fade-in-opacity">
						<SVG
							src={bgElement1}
							className="absolute -left-12 bottom-[4rem] w-10 fill-black3 rotate-[30deg]"
						/>
						<SVG
							src={bgElement5}
							className="hidden md:block absolute left-[-0.72rem] top-[18rem] w-4 rotate-[30deg]"
						/>
					</div>
				</div>
				<div className="h-full w-full absolute top-0 left-0 delayed-fade-in-opacity">
					<SVG
						src={bgElement3}
						className="absolute right-20 top-10 w-5 fill-light2"
					/>
					<SVG
						src={bgElement3}
						className="absolute right-56 top-60 w-5 fill-light2"
					/>
				</div>
			</div>

			<div className="max-w-6xl w-full mx-auto flex flex-col gap-8 pt-24 pb-16 px-2 lg:px-0 z-30 relative">
				<div className="flex flex-row">
					<div className="flex flex-1 flex-col mx-2 lg:mx-auto mb-8 lg:mb-0 gap-4 after:bg-light1 after:absolute after:-z-10 after:left-1 after:top-1 after:right-1 after:bottom-1 lore-header-bg-clip px-8 lg:px-24 py-12 relative border lg:border-l-0 lg:border-r-0 border-t border-b border-black5">
						<Heading
							type={1}
							className="!text-3xl text-left"
							color={CONFIG_COLORS.BLACK_5}
						>
							{title}
						</Heading>
						<p>{description}</p>
						<div className="hidden lg:block">
							<span className="flex flex-col w-2 h-full absolute -top-0 -left-0 z-10">
								<span className="flex-1 border-l border-black5" />
								<span className="h-[6.3rem] lg:h-20 w-2 relative">
									<span className="block w-14 lg:w-[3.3rem] h-px absolute top-0 left-0 transform rotate-[65deg] lg:rotate-[48deg] origin-top-left bg-black5 z-10" />
									<span className="block w-14 lg:w-[3.3rem] h-px absolute bottom-0 left-0 transform -rotate-[65deg] lg:-rotate-[48deg] origin-bottom-left bg-black5 z-10" />
								</span>
								<span className="flex-1 border-l border-black5" />
							</span>
							<span className="flex flex-col w-2 h-full absolute -top-0 -right-0 z-10">
								<span className="flex-1 border-r border-black5" />
								<span className="h-[6.3rem] lg:h-20 w-2 relative">
									<span className="block w-14 lg:w-[3.3rem] h-px absolute top-0 right-0 transform -rotate-[65deg] lg:-rotate-[48deg] origin-top-right bg-black5 z-10" />
									<span className="block w-14 lg:w-[3.3rem] h-px absolute bottom-0 right-0 transform rotate-[65deg] lg:rotate-[48deg] origin-bottom-right bg-black5 z-10" />
								</span>
								<span className="flex-1 border-r border-black5" />
							</span>
						</div>
					</div>

					<div className="hidden lg:block lg:flex-1 z-20">
						<div className="absolute -top-[20%] w-full -right-[50%]">
							<GatsbyImage
								image={backgroundCharacter.featuredImage.gatsbyImageData}
								alt={backgroundCharacter.featuredImage.alt}
								className="object-contain w-[100%] h-full"
							/>
						</div>
					</div>
				</div>
				<div className="grid grid-cols-1 gap-4 md:grid-cols-3 w-full">
					{items.map((article, index) => (
						<LoreBox article={article} key={index} />
					))}
				</div>
			</div>
		</section>
	)
}

export default LoreSection
