import React from 'react'
import ArticleBox from '../ArticleBox'
import Heading from '../Heading/Heading'

export interface IArticleSectionProps {
	title: string
	items: {
		__typename: string
		id: string
		title: string
		slug: string
		coverImage: {
			url: string
			gatsbyImageData: any
			alt: string
		}
		category: {
			title: string
			slug: string
		}
		publishedAt: string
	}[]
}

const ArticleSection: React.FC<IArticleSectionProps> = ({ title, items }) => {
	return (
		<section className="mx-auto flex flex-col gap-4 w-full items-center lg:items-start z-30">
			<Heading type={1}>{title}</Heading>
			<div className="grid grid-cols-1 gap-4 lg:grid-cols-3 w-full">
				{items.map((article, index) => (
					<ArticleBox article={article} key={index} large={index === 0} />
				))}
			</div>
		</section>
	)
}

export default ArticleSection
