import React from 'react'

import paginationEl1 from '@/images/pagination-element-1.svg'
import paginationEl2 from '@/images/pagination-element-2.svg'
import paginationEl3 from '@/images/pagination-element-3.svg'
import SVG from '@/components/SVG'
import { useInView } from 'react-intersection-observer'

interface IRosterCarouselNavigationProps {
	characters: any[]
	activeCharacterIndex: number
}

const RosterCarouselNavigation: React.FC<IRosterCarouselNavigationProps> = ({
	characters,
	activeCharacterIndex,
}) => {
	const { ref, inView, entry } = useInView({
		/* Optional options */
		threshold: 0.3,
		triggerOnce: true,
	})
	return (
		<div
			ref={ref}
			className={`relative flex justify-between items-center w-full lg:w-6 h-6 lg:h-full mx-0 my-2`}
		>
			<div
				className={`h-0.5 w-[98%] bg-teal absolute left-0 right-0 mx-auto ${
					inView ? `fade-in-from-middle` : `opacity-0`
				}`}
			/>
			{characters.map((c, i) => (
				<React.Fragment key={`${i}`}>
					<div
						key={i}
						className={`relative ${inView ? `delayed-fade-in-opacity` : `opacity-0`}`}
					>
						<SVG
							src={paginationEl2}
							className={`w-3 transition-all duration-500  ${
								i === activeCharacterIndex ? `` : `hidden`
							}`}
						/>
						<SVG
							src={paginationEl3}
							className={`absolute -top-2 -left-3.5 w-10  ${
								i === activeCharacterIndex ? `` : `hidden`
							}`}
						/>
						<SVG
							src={paginationEl1}
							className={`w-3 transition-all duration-500 ${
								i === activeCharacterIndex ? `hidden` : ``
							}`}
						/>
					</div>
				</React.Fragment>
			))}
		</div>
	)
}

export default RosterCarouselNavigation
