import SVG from '@/components/SVG'
import chevronIcon from '@/images/dropdown-chevron.svg'
import diamondIcon from '@/images/video-diamond.svg'
import BackgroundImage from 'gatsby-background-image'
import { getImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import React, { BaseSyntheticEvent, useState } from 'react'
import { useInView } from 'react-intersection-observer'

interface IFilterCategoryDropdownProps {
	options: { value: any; label: string; bgImage: any; hasDarkText: boolean }[]
	theme?: 'light' | 'dark'
	value: any
	onChange(e: string): void
	className?: string
}

const FilterCategoryDropdown: React.FC<IFilterCategoryDropdownProps> = ({
	options,
	theme = `dark`,
	value,
	onChange,
	className,
}) => {
	const [isOpen, setIsOpen] = useState<boolean>(false)

	const handleIsOpen = () => {
		setIsOpen(!isOpen)
	}
	const { ref, inView, entry } = useInView({
		/* Optional options */
		threshold: 0.3,
		triggerOnce: true,
	})
	return (
		<div
			ref={ref}
			className={` ${
				inView ? `fade-in-opacity` : `opacity-0`
			} relative min-w-[15rem] w-60 h-16 mx-2 group`}
			onClick={handleIsOpen}
		>
			<SVG
				src={diamondIcon}
				className="absolute h-2 -left-2 top-[calc(50%-0.25rem)] fill-black3"
			/>
			<SVG
				src={diamondIcon}
				className="absolute h-2 -right-2 top-[calc(50%-0.25rem)] fill-black3"
			/>
			<div
				className={`cursor-pointer text-input-clip py-5 text-center text-sm px-4 border-black3 z-20 w-full h-full relative group-hover:bg-black3 group-hover:text-light2 group-hover:border-none transition-all duration-300 ${
					isOpen
						? `bg-black3 text-light2 border-none`
						: `bg-transparent text-black3 border`
				}`}
			>
				<div
					className={`absolute bg-black3 w-2 h-2 rotate-45 -top-[0.4rem] left-[calc(50%-0.25rem)]`}
				/>
				<div
					className={`absolute bg-black3 w-2 h-2 rotate-45 -bottom-[0.4rem] left-[calc(50%-0.25rem)]`}
				/>
				<div
					className={`absolute bg-black3 w-2 h-2 rotate-45 top-[calc(50%-0.25rem)] -left-[0.45rem]`}
				/>
				<div
					className={`absolute bg-black3 w-2 h-2 rotate-45 top-[calc(50%-0.25rem)] -right-[0.45rem]`}
				/>
				<div className="flex items-center justify-center h-full">
					<p className="font-evercore text-xl uppercase h-full m-0 p-0 flex items-center justify-center">
						{value.length > 0 ? value : `Select Category`}
					</p>
					<SVG
						src={chevronIcon}
						className={`ml-4 h-3 group-hover:fill-light2 ${
							isOpen ? `rotate-90 fill-light2` : ``
						}`}
					/>
				</div>
			</div>
			{isOpen && (
				<div
					role="tab"
					className="absolute w-60 top-[calc(4rem+1px)] bg-black3 text-input-clip z-30 py-2"
				>
					{options.map(
						(
							option: {
								value: any
								label: string
								bgImage: any
								hasDarkText: boolean
							},
							i: number
						) => {
							const bgImage = getImage(option.bgImage)
							const bg = convertToBgImage(bgImage)
							return (
								<div
									role="tablist"
									key={i}
									className="option py-2 px-4 cursor-pointer"
									onClick={() => onChange(option.value)}
								>
									<div
										className={`filter-option-clip h-8 w-full ${
											option.hasDarkText ? `text-black5` : `text-light2`
										}`}
									>
										<BackgroundImage
											{...bg}
											className="w-full h-full flex justify-center items-center relative"
										>
											<div className="bg-black3 opacity-20 w-full h-full absolute"></div>
											<p className="z-10">{option.label}</p>
										</BackgroundImage>
									</div>
								</div>
							)
						}
					)}
				</div>
			)}
		</div>
	)
}

export default FilterCategoryDropdown
