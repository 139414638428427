import React, { useState } from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import BackgroundImage from 'gatsby-background-image'
import SVG from 'react-inlinesvg'

import { useLightboxState } from '@/context'

import videoPlayIcon from '@/images/video-play-icon.svg'
import carouselArrowLeft from '@/images/carousel-arrow-left.svg'
import carouselArrowRight from '@/images/carousel-arrow-right.svg'

import bgElement1 from '@/images/bg-elements-1.svg'
import bgElement2 from '@/images/bg-elements-2.svg'
import bgElement4 from '@/images/bg-elements-4.svg'
import bgElement5 from '@/images/bg-elements-5.svg'

import Blade from '../Blade'
import PinkNav from '../PinkNav'

import './style.scss'
import Heading from '../Heading/Heading'

export interface ICharacter {
	name: string
	featuredImage: any // TODO: typing
}

export interface ICharacterAbility {
	name: string
	defaultIcon: any // TODO: typing
	selectedIcon: any // TODO: typing
	description: string
}

export interface IGameplayCarouselBladeSlide {
	slideType: string
	subheading: string | undefined
	heading: string | undefined
	body: string | undefined
	image: any // TODO: correct typing
	video: any // TODO: correct typing
	active: boolean
	character: ICharacter
}

export interface IGameplayCarouselBladeProps {
	__typename: string
	id: string
	slides: IGameplayCarouselBladeSlide[]
	key: number
	className: string
}

const VideoSlide: React.FC<IGameplayCarouselBladeSlide> = ({
	index,
	_index,
	subheading,
	heading,
	body,
	image,
	video,
	active = false,
}) => {
	const {
		setLightboxOpen,
		setActiveLightboxImage,
		setLightboxImages,
		setLightboxVideo,
	} = useLightboxState()
	const before = _index < index

	const handleVideoClick = () => {
		if (!video) return false

		setActiveLightboxImage(0)
		setLightboxImages([])
		setLightboxVideo(video)
		setLightboxOpen(true)
	}

	return (
		<div
			className={`w-full lg:h-full lg:absolute lg:top-0 lg:left-0 ${
				active
					? `block opacity-100 visible translate-x-0`
					: `${
							before ? `-translate-x-full` : `translate-x-full`
					  } hidden lg:block opacity-0 invisible pointer-events-none`
			} transition-all duration-500`}
		>
			<div className="flex flex-col-reverse lg:flex-row items-start justify-between w-full h-full">
				<div className="w-full lg:w-1/2 p-4 lg:p-8">
					{subheading ? (
						<span className="block text-base md:text-lg lg:text-xl uppercase italic">
							{subheading}
						</span>
					) : null}

					{heading ? (
						<Heading
							type={3}
							className="max-w-lg mx-auto lg:mx-0  text-3xl md:text-4xl lg:text-5xl xl:text-6xl uppercase"
						>
							{heading}
						</Heading>
					) : null}

					{body ? (
						<p className="max-w-md mx-auto lg:mx-0 mt-4 md:mt-8 text-base md:text-lg font-light">
							{body}
						</p>
					) : null}
				</div>

				<div className="w-full lg:w-1/2 p-4 lg:p-8">
					<div className="w-full lg:w-[calc(100%+8rem)] p-1.5 transform border border-black1">
						<div
							className={`group w-full pt-[56.25%] relative ${
								video ? `cursor-pointer` : ``
							}`}
							onClick={handleVideoClick}
						>
							{image ? (
								<BackgroundImage
									{...convertToBgImage(getImage(image))}
									className="w-full h-full absolute top-0 left-0 bg-cover bg-center bg-norepeat"
									style={{ position: `absolute` }}
								/>
							) : null}

							{video ? (
								<div
									className={`flex justify-center items-center w-16 group-hover:w-20 h-16 group-hover:h-20 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-black5 rounded-full transition-all`}
								>
									<SVG
										src={videoPlayIcon}
										className="w-5 h-5 ml-0.5 absolute top-1/2 left-1/2 fill-light1 transform -translate-x-1/2 -translate-y-1/2"
									/>
								</div>
							) : null}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

const CharacterSlide: React.FC<IGameplayCarouselBladeSlide> = ({
	index,
	_index,
	subheading,
	heading,
	body,
	character,
	active = false,
}) => {
	const [abilityIndex, setAbilityIndex] = useState(0)
	const before = _index < index

	const handleAbilitySelect = (index: number) => {
		setAbilityIndex(index)
	}

	return (
		<div
			className={`w-full lg:h-full lg:absolute lg:top-0 lg:left-0 ${
				active
					? `block opacity-100 visible translate-x-0`
					: `${
							before ? `-translate-x-full` : `translate-x-full`
					  } hidden lg:block opacity-0 invisible pointer-events-none`
			} transition-all duration-500`}
		>
			<div className="flex flex-col lg:flex-row items-start justify-between w-full h-full">
				<div className="flex-1 w-full h-full lg:w-2/5 xl:w-1/2 p-4 lg:p-8 relative">
					<GatsbyImage
						image={getImage(character?.featuredImage)}
						className="w-56 md:w-64 lg:w-[150%] xl:w-full max-w-[200%] h-auto -mt-20 lg:mt-0 lg:absolute lg:-bottom-20 xl:-bottom-32 lg:left-0 transform lg:-translate-x-20 xl:translate-x-0"
						style={{ position: null }}
					/>
				</div>

				<div className="w-full lg:w-3/5 xl:w-1/2 pt-0 sm:pt-4 p-4 lg:p-8">
					{subheading ? (
						<span className="block text-3xl md:text-4xl lg:text-5xl xl:text-6xl uppercase italic">
							{subheading}
						</span>
					) : null}

					{heading ? (
						<h1 className="max-w-lg mx-auto lg:mx-0 font-sans font-medium text-base md:text-lg lg:text-xl uppercase">
							{heading}
						</h1>
					) : null}

					{body ? (
						<p className="max-w-md mx-auto lg:mx-0 mt-4 md:mt-8 text-base md:text-lg font-light">
							{body}
						</p>
					) : null}

					{character?.abilities?.length > 0 ? (
						<div className="w-full mt-4 md:mt-8">
							<h3 className="flex items-center w-full mb-4 text-center">
								<span className="flex-1 h-px bg-black1" />
								<span className="inline-block px-4 font-display text-sm tracking-wide uppercase">
									Augments
								</span>
								<span className="flex-1 h-px bg-black1" />
							</h3>

							<div className="flex items-center justify-between -m-4">
								{character?.abilities?.map(
									(
										{ name, defaultIcon, selectedIcon }: ICharacterAbility,
										_abilityIndex
									) => (
										<button
											key={_abilityIndex}
											className="flex flex-col items-center justify-start p-4 appearance-none select-none"
											onMouseEnter={() => handleAbilitySelect(_abilityIndex)}
										>
											{_abilityIndex == abilityIndex ? (
												<GatsbyImage
													image={getImage(defaultIcon)}
													className="w-12 h-auto"
												/>
											) : (
												<GatsbyImage
													image={getImage(selectedIcon)}
													className="w-12 h-auto"
												/>
											)}
											<span
												className={`text-xs ${
													_abilityIndex === abilityIndex ? 'text-black1' : 'text-light3'
												} uppercase italic`}
											>
												{name}
											</span>
										</button>
									)
								)}
							</div>
						</div>
					) : null}
				</div>
			</div>
		</div>
	)
}

const GameplayCarouselBlade: React.FC<IGameplayCarouselBladeProps> = ({
	key,
	slides,
	className,
}) => {
	const [index, setIndex] = useState<number>(0)

	const handlePrev = () => {
		setIndex(Math.max(0, index - 1))
	}

	const handleNext = () => {
		setIndex(Math.min(slides.length - 1, index + 1))
	}

	return (
		<div className="relative overflow-hidden z-10 text-center lg:text-left">
			<span className="clip-bg-triangle block w-[90rem] h-[90rem] absolute top-0 left-1/3 md:left-1/2 bg-teal z-10">
				<span className="block w-px h-double absolute top-1/2 left-1/3 lg:left-1/2 transform -translate-x-1/2 -translate-y-1/2 origin-center -rotate-[26.6deg] bg-black1 bg-opacity-20" />

				<SVG
					src={bgElement4}
					className="bg-light1 text-light1 fill-light1 h-2 w-4 absolute top-40 left-96 clipped-parallelogram-alt left-[5.1rem] pointer-events-none"
				/>
				<SVG
					src={bgElement4}
					className="bg-light1 text-light1 fill-light1 h-2 w-4 absolute top-80 left-96 clipped-parallelogram-alt left-[10.8rem] pointer-events-none"
				/>
			</span>

			<div className="w-full lg:py-20 xl:py-32 relative bg-light2 overflow-hidden">
				<span className="block w-px h-double absolute top-1/2 left-1/3 transform -translate-x-1/2 -translate-y-1/2 origin-center -rotate-[26.6deg] bg-black1 bg-opacity-20" />

				<Blade className="max-w-8xl mx-auto z-100">
					<div className="p-2 relative border-t border-b border-black1 z-20">
						<div className="p-4 md:p-8 lg:p-12 relative z-20">
							<div className="gameplay-carousel-blade__fg w-full h-full absolute top-0 left-0 bg-light1" />

							<span className="flex flex-col block w-2 h-[calc(100%+1rem)] absolute -top-2 -left-2 z-10">
								<span className="flex-1 border-l border-black1" />
								<span className="h-11 lg:h-32 w-2 relative">
									<span className="block w-7 lg:w-20 h-px absolute top-0 left-0 transform rotate-[53deg] origin-top-left bg-black1 z-30" />
									<span className="block w-7 lg:w-20 h-px absolute bottom-0 left-0 transform -rotate-[53deg] origin-bottom-left bg-black1 z-30" />
								</span>
								<span className="flex-1 border-l border-black1" />
							</span>

							<span className="flex flex-col block w-2 h-[calc(100%+1rem)] absolute -top-2 -right-2 z-10">
								<span className="flex-1 border-r border-black1" />
								<span className="h-11 lg:h-32 w-2 relative">
									<span className="block w-7 lg:w-20 h-px absolute top-0 right-0 transform -rotate-[53deg] origin-top-right bg-black1 z-30" />
									<span className="block w-7 lg:w-20 h-px absolute bottom-0 right-0 transform rotate-[53deg] origin-bottom-right bg-black1 z-30" />
								</span>
								<span className="flex-1 border-r border-black1" />
							</span>

							<div className="h-[40rem] md:h-[48rem] lg:h-[30rem] relative z-20">
								{slides?.map((slide: IGameplayCarouselBladeSlide, _index: number) => (
									<>
										{slide?.slideType === `video` ? (
											<VideoSlide
												{...{ ...slide, index, _index, active: _index === index }}
											/>
										) : (
											<CharacterSlide
												{...{ ...slide, index, _index, active: _index === index }}
											/>
										)}
									</>
								))}

								<div className="flex items-center justify-start h-12 absolute bottom-4 md:bottom-0 left-4 md:left-0">
									<button
										className="group inline-flex items-center justify-center w-12 h-12 relative text-center z-60"
										onClick={handlePrev}
									>
										<span className="block w-12 h-12 absolute top-0 left-0 transform origin-center group-hover:scale-110 bg-black5 text-light1 rounded-full transition-all" />
										<SVG className="w-4 h-auto transform" src={carouselArrowLeft} />
									</button>
									<button
										className="group inline-flex items-center justify-center w-12 h-12 ml-4 md:ml-6 relative text-center z-60"
										onClick={handleNext}
									>
										<span className="block w-12 h-12 absolute top-0 left-0 transform origin-center group-hover:scale-110 bg-black5 text-light1 rounded-full transition-all" />
										<SVG className="w-4 h-auto transform" src={carouselArrowRight} />
									</button>
								</div>

								<PinkNav
									{...{
										count: slides?.length,
										index,
										setIndex,
										className:
											'justify-end lg:justify-center w-1/2 absolute bottom-4 md:bottom-0 right-4 md:right-0',
									}}
								/>
							</div>
						</div>
					</div>
				</Blade>
			</div>

			<div className="absolute w-full h-full absolute top-0 left-0 z-30 pointer-events-none">
				<SVG
					src={bgElement1}
					className="absolute left-10 top-[16rem] w-10 fill-black3"
				/>
				<SVG src={bgElement2} className="absolute left-72 top-72 w-5 fill-black3" />

				<div className="w-[1px] h-[110%] absolute left-[60%] top-0 -rotate-[30deg]">
					<SVG
						src={bgElement1}
						className="absolute -left-12 bottom-[4rem] w-10 fill-black3 rotate-[30deg]"
					/>
				</div>
			</div>
		</div>
	)
}

export default GameplayCarouselBlade
