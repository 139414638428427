import ThumbnailBorder from '@/components/ImageBorders/ThumbnailBorder'
import MediaItem from '@/components/MediaItem'
import React from 'react'
import { IBoss } from '..'
import { useInView } from 'react-intersection-observer'

interface IThumbnailCarouselProps {
	characters: IBoss[]
	activeCharacterIndex: number
}

const DesktopThumbnailCarousel: React.FC<IThumbnailCarouselProps> = ({
	characters,
	activeCharacterIndex,
}) => {
	const { ref, inView, entry } = useInView({
		/* Optional options */
		threshold: 0.3,
		triggerOnce: true,
	})

	const prevCharIndex =
		activeCharacterIndex - 1 >= 0
			? activeCharacterIndex - 1
			: characters.length - 1
	const nextCharIndex =
		activeCharacterIndex + 1 <= characters.length - 1
			? activeCharacterIndex + 1
			: 0

	return (
		<div
			ref={ref}
			className={`hidden lg:block absolute h-full w-full z-10 ${
				inView ? `delayed-fade-in-opacity` : `opacity-0`
			}`}
		>
			{characters.map((character, index) => {
				const prevImageClasses = `absolute top-[calc(50%+0.5rem)] translate-x-[35%]`
				const activeImageClasses = `absolute top-[calc(50%-56px)] -translate-x-[70%]`
				const nextImageClasses = `absolute top-[calc(50%-5.5rem)] translate-x-[35%]`
				const hiddenImagesClasses = `absolute top-[calc(50%-2.5rem)] translate-x-[200%] opacity-0`

				return (
					<ThumbnailBorder
						className={`${
							index === prevCharIndex
								? prevImageClasses
								: index === activeCharacterIndex
								? activeImageClasses
								: index === nextCharIndex
								? nextImageClasses
								: hiddenImagesClasses
						} transition-all duration-500 delay-300`}
						active={activeCharacterIndex === index}
						key={index}
					>
						<MediaItem
							format={character.thumbnail.format}
							isImage={character.thumbnail.isImage}
							gatsbyImageData={character.thumbnail.gatsbyImageData}
							svgSrc={character.thumbnail.url}
							mediaItemClasses={`max-w-lg transition-all duration-500 delay-300`}
						/>
					</ThumbnailBorder>
				)
			})}
			{/* {characters[activeCharacterIndex - 1] ? (
				<ThumbnailBorder className="absolute -right-[6.5rem] top-12 transition-all duration-500 delay-300">
					<MediaItem
						format={characters[activeCharacterIndex - 1].thumbnail.format}
						isImage={characters[activeCharacterIndex - 1].thumbnail.isImage}
						gatsbyImageData={
							characters[activeCharacterIndex - 1].thumbnail.gatsbyImageData
						}
						svgSrc={characters[activeCharacterIndex - 1].thumbnail.url}
						mediaItemClasses="max-w-lg transition-all duration-500 delay-300"
					/>
				</ThumbnailBorder>
			) : (
				<ThumbnailBorder className="absolute -right-[6.5rem] top-12 transition-all duration-500 delay-300">
					<MediaItem
						format={characters[characters.length - 1].thumbnail.format}
						isImage={characters[characters.length - 1].thumbnail.isImage}
						gatsbyImageData={
							characters[characters.length - 1].thumbnail.gatsbyImageData
						}
						svgSrc={characters[characters.length - 1].thumbnail.url}
						mediaItemClasses="max-w-lg transition-all duration-500 delay-300"
					/>
				</ThumbnailBorder>
			)}

			<ThumbnailBorder active>
				<MediaItem
					format={characters[activeCharacterIndex].thumbnail.format}
					isImage={characters[activeCharacterIndex].thumbnail.isImage}
					gatsbyImageData={
						characters[activeCharacterIndex].thumbnail.gatsbyImageData
					}
					svgSrc={characters[activeCharacterIndex].thumbnail.url}
					mediaItemClasses="max-w-lg transition-all duration-500 delay-300"
				/>
			</ThumbnailBorder>

			{characters[activeCharacterIndex + 1] ? (
				<ThumbnailBorder className="absolute -right-[6.5rem] bottom-12 transition-all duration-500 delay-300">
					<MediaItem
						format={characters[activeCharacterIndex + 1].thumbnail.format}
						isImage={characters[activeCharacterIndex + 1].thumbnail.isImage}
						gatsbyImageData={
							characters[activeCharacterIndex + 1].thumbnail.gatsbyImageData
						}
						svgSrc={characters[activeCharacterIndex + 1].thumbnail.url}
						mediaItemClasses="max-w-lg w-full h-full transition-all duration-500 delay-300"
					/>
				</ThumbnailBorder>
			) : (
				<ThumbnailBorder className="absolute -right-[6.5rem] bottom-12 transition-all duration-500 delay-300">
					<MediaItem
						format={characters[0].thumbnail.format}
						isImage={characters[0].thumbnail.isImage}
						gatsbyImageData={characters[0].thumbnail.gatsbyImageData}
						svgSrc={characters[0].thumbnail.url}
						mediaItemClasses="max-w-lg w-full h-full transition-all duration-500 delay-300"
					/>
				</ThumbnailBorder>
			)} */}
		</div>
	)
}

export default DesktopThumbnailCarousel
