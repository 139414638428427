import { useLightboxState } from '@/context'
import bgElement1 from '@/images/bg-elements-1.svg'
import bgElement2 from '@/images/bg-elements-2.svg'
import bgElement3 from '@/images/bg-elements-3.svg'
import bgElement4 from '@/images/bg-elements-4.svg'
import bgElement5 from '@/images/bg-elements-5.svg'
import diamondIcon from '@/images/video-diamond.svg'
import { IExternalVideo, IImageBlock } from '@/types'
import React from 'react'
import Blade from '../Blade'
import { IButtonProps } from '../Blades'
import Button from '../Button'
import Heading from '../Heading/Heading'
import Link from '../Link'
import MediaItem from '../MediaItem'
import SVG from '../SVG'
import VideoBorder from '../VideoBorder'
import './styles.scss'
import { useInView } from 'react-intersection-observer'

interface ITextMediaBladeProps {
	addDescription: boolean
	addTitle: boolean
	addTitleTagline: boolean
	backgroundBackdropColour: { hex: string }
	backgroundImage: any
	buttons: IButtonProps[]
	description: string
	media: IImageBlock[] | IExternalVideo[]
	textIsOnTheLeft: boolean
	title: string
	titleTagline: string
	removeBg?: boolean
	isFirst: boolean
}

const TextMediaBlade: React.FC<ITextMediaBladeProps> = ({
	className,
	...props
}) => {
	const {
		addDescription,
		addTitle,
		addTitleTagline,
		backgroundImage,
		buttons,
		description,
		media,
		textIsOnTheLeft,
		title,
		titleTagline,
		removeBg,
		isFirst,
	} = props
	const { ref, inView, entry } = useInView({
		/* Optional options */
		threshold: 0.3,
		triggerOnce: true,
	})
	const {
		setLightboxImages,
		setLightboxOpen,
		setLightboxVideo,
		setActiveLightboxImage,
	} = useLightboxState()

	const handleLightbox = (mediaItem: any) => {
		const isVideo = !!(
			mediaItem.externalVideo.url || mediaItem.internalVideo.video.mp4Url
		)

		setActiveLightboxImage(0)
		if (isVideo && mediaItem?.externalVideo)
			setLightboxVideo(mediaItem.externalVideo)
		if (isVideo && !mediaItem?.externalVideo)
			setLightboxVideo(mediaItem.internalVideo.video)
		if (!isVideo) setLightboxImages([mediaItem.image])
		setLightboxOpen(true)
	}

	return (
		<Blade
			className={`z-10 relative flex flex-col px-0 items-center bg-light2 ${
				isFirst ? `pt-32 md:py-48` : `md:py-32`
			} ${textIsOnTheLeft && `md:flex-row`}`}
		>
			<div
				id="text-media-blade"
				className="absolute w-full h-full top-0 left-0"
				ref={ref}
			>
				<div className="relative w-full h-full">
					<SVG
						src={bgElement1}
						className="absolute left-10 top-[16rem] w-10 fill-black3 delayed-fade-in-opacity"
					/>
					<SVG
						src={bgElement2}
						className="hidden md:block absolute left-72 top-72 w-5 fill-black3 delayed-fade-in-opacity"
					/>
					{!removeBg && (
						<div className="absolute right-0 top-0 teal-triangle-bg">
							<SVG
								src={bgElement4}
								className="hidden md:block bg-black3 h-2 w-4 absolute top-40 clipped-parallelogram-alt left-[5.1rem] delayed-fade-in-opacity"
							/>
							<SVG
								src={bgElement4}
								className="hidden md:block bg-black3 h-2 w-4 absolute top-80 clipped-parallelogram-alt left-[10.8rem] delayed-fade-in-opacity"
							/>
						</div>
					)}
					{textIsOnTheLeft && (
						<div className="w-[1px] h-[110%] absolute bg-black1 left-[60%] top-0 -rotate-[30deg] delayed-fade-in-opacity">
							<SVG
								src={bgElement1}
								className="absolute -left-12 bottom-[4rem] w-10 fill-black3 rotate-[30deg]"
							/>
							<SVG
								src={bgElement5}
								className="hidden md:block absolute left-[-0.72rem] top-[18rem] w-4 rotate-[30deg]"
							/>
						</div>
					)}
				</div>
			</div>
			<div className="h-full w-full absolute top-0 left-0 delayed-fade-in-opacity">
				<SVG
					src={bgElement3}
					className="absolute right-20 top-10 w-5 fill-light2"
				/>
				<SVG
					src={bgElement3}
					className="absolute right-56 top-60 w-5 fill-light2"
				/>
			</div>

			<div
				className={`w-full px-4 md:px-0 items-center flex flex-col z-10
					${textIsOnTheLeft ? `md:items-start md:mr-20 md:w-2/5` : `md:items-center`}
				`}
			>
				<div
					className={`text-center ${
						textIsOnTheLeft ? `md:text-left` : `md:text-center`
					}`}
				>
					{addTitleTagline && (
						<Heading
							className={` ${inView ? `top-left-corner-fade-in` : `opacity-0`}`}
							type={6}
						>
							{titleTagline}
						</Heading>
					)}
					{addTitle && (
						<Heading
							className={`mb-8 ${inView ? `top-left-corner-fade-in` : `opacity-0`}`}
						>
							{title}
						</Heading>
					)}
					{addDescription && (
						<p
							className={`mb-8 px-8 md:px-0 text-md md:text-lg ${
								inView ? `fade-in-from-top-delayed` : `opacity-0`
							} ${
								textIsOnTheLeft
									? `md:text-left`
									: `md:text-center md:max-w-3xl md:mx-auto`
							}`}
						>
							{description}
						</p>
					)}
				</div>
				{buttons &&
					buttons.length > 0 &&
					buttons.map((button: IButtonProps, i: number) => (
						<Link
							key={`charBladeBtn-${i + 1}`}
							external={button.isExternal}
							to={button.isExternal ? button.externalLink : button.link}
						>
							<Button
								icon={button.buttonIcon}
								text={button.text}
								color={button.buttonColour}
								hasIcon={button.hasIcon}
								innerBtnClassName=" w-full md:w-fit"
								containerClassName="w-full md:w-fit mb-12"
							/>
						</Link>
					))}
			</div>
			<div className="w-full md:w-3/5 p-4">
				{(media[0] as IExternalVideo).externalVideo ? (
					<div
						onClick={() => {
							handleLightbox(media[0])
						}}
						className="cursor-pointer"
					>
						<VideoBorder
							hasOverlayImage={false}
							className="relative md:mx-auto w-fit mx-2.5 "
						>
							<MediaItem
								format="video"
								videoUrl={(media[0] as IExternalVideo).externalVideo.url}
								thumbnailUrl={(media[0] as IExternalVideo).thumbnailVideo.url}
								mediaItemClasses={`video-clip w-full md:max-w-3xl absolute`}
								animateIn={`grow-fade-in-middle-video`}
								animateStart={`opacity-0`}
							/>
						</VideoBorder>
					</div>
				) : (
					<div
						onClick={() => {
							handleLightbox(media[0])
						}}
						className="cursor-pointer"
					>
						<MediaItem
							format={(media[0] as IImageBlock).image.format}
							svgSrc={(media[0] as IImageBlock).image.url}
							isImage={(media[0] as IImageBlock).image.isImage}
							gatsbyImageData={(media[0] as IImageBlock).image.gatsbyImageData}
							mediaItemClasses={`w-full md:max-w-xl mx-auto ${
								inView ? `grow-fade-in-middle-video` : `opacity-0`
							}`}
							className={`${inView ? `grow-fade-in-middle-video` : `opacity-0`}`}
						/>
					</div>
				)}
			</div>
			<div className="-z-10 absolute bottom-0 left-0 right-0 w-full h-32 md:h-[13.2rem] bg-light2">
				<SVG
					src={diamondIcon}
					className={`h-32 w-20 absolute -bottom-16 -left-10`}
				/>
			</div>
		</Blade>
	)
}

export default TextMediaBlade
