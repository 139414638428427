import MediaItem from '@/components/MediaItem'
import { GatsbyImageProps } from 'gatsby-plugin-image'
import React, { useRef } from 'react'
import cx from 'classnames'
import { CSSTransition } from 'react-transition-group'

interface ICharacterImageProps {
	gatsbyImageData: GatsbyImageProps
	isImage: boolean
	format: string
	svgSrc: string
	className: string
	show: boolean
}

const CharacterImage: React.FC<ICharacterImageProps> = ({
	gatsbyImageData,
	isImage,
	format,
	svgSrc,
	className,
	show,
}) => {
	const classes = cx(
		`clipped-diamond absolute block w-[47%] h-[28rem]  md:w-[17rem] lg:w-[21.5rem] xl:w-[25rem]  md:h-[40rem]  lg:h-[48rem] xl:h-[55rem] 2xl:w-[30rem] ${
			show ? `scale-100` : `scale-0`
		}`,
		className
	)
	const imgRef = useRef()
	return (
		<span className={classes}>
			<MediaItem
				gatsbyImageData={gatsbyImageData}
				isImage={isImage}
				format={format}
				svgSrc={svgSrc}
				className=" z-1"
				imageClasses={`absolute h-[110%] top-0 `}
			/>
		</span>
	)
}

export default CharacterImage
