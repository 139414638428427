import React from 'react'
import cx from 'classnames'

interface IThumbnailBorderProps {
	children: React.ReactNode
	active?: boolean
	className?: string
}

const ThumbnailBorder: React.FC<IThumbnailBorderProps> = ({
	children,
	active = false,
	className,
}) => {
	const classes = cx(
		` ${
			active ? `m-4` : ``
		} w-[6rem] h-[5rem] group transition-all duration-500 delay-300`,
		className
	)

	return (
		<div className={classes}>
			<span
				className={`${
					active ? `opacity-100` : `opacity-0`
				} transition-all duration-500 delay-300`}
			>
				<div
					className={`absolute h-[1px] w-[calc(50%+10px)] animate-pulse top-[calc(50%-calc((50%+10px)/2))] bg-light2 -right-6 rotate-[60deg]`}
				>
					<div
						className={`absolute h-[1px] w-[20px] bg-light2 top-[8.5px] -left-[14.5px] -rotate-[60deg]`}
					></div>
					<div
						className={`absolute h-[1px] w-[20px] bg-light2 top-[8.5px] -right-[14.5px] rotate-[60deg]`}
					></div>
				</div>
				<div
					className={`absolute h-[1px] w-[calc(50%+10px)] animate-pulse bottom-[calc(50%-calc((50%+10px)/2))] bg-light2 -left-6 -rotate-[120deg]`}
				>
					<div
						className={`absolute h-[1px] w-[20px] bg-light2 top-[8.5px] -left-[14.5px] -rotate-[60deg]`}
					></div>
					<div
						className={`absolute h-[1px] w-[20px] bg-light2 top-[8.5px] -right-[14.5px] rotate-[60deg]`}
					></div>
				</div>
				<div className={`clip-hexagon bg-teal scale-110 w-[6rem] h-[5rem] `}></div>
			</span>

			<div
				className={`absolute top-0 left-0 clip-hexagon w-[6rem] h-[5rem] transition-all duration-500 delay-300`}
			>
				{children}
			</div>
			<div
				className={`clip-hexagon  absolute top-0 left-0 w-[6rem] h-[5rem] transition-all duration-500 delay-300 ${
					active ? `` : `bg-black5 opacity-60`
				}`}
			/>
		</div>
	)
}

export default ThumbnailBorder
