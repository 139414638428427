import React from 'react'

interface IPinkNav {
	count: number
	index: number
	setIndex: any
	activeColor: string
	clickable: boolean
	className: string | null
}

const PinkNav: React.FC<IPinkNav> = ({
	count,
	index,
	setIndex,
	activeColor = `black`,
	clickable = true,
	className,
}) => {
	const _items = []

	let _bg = `bg-black3`
	let _boc = `border-black3`
	switch (activeColor) {
		case `white`:
			_bg = `bg-light1`
			_boc = `border-light1`
			break
	}

	for (let _index = 0; _index < count; _index++) {
		_items.push(
			<>
				{_index > 0 ? (
					<span className="w-6 min-w-[1.5rem] sm:w-10 sm:min-w-[2.5rem] h-1 -mx-1 relative z-30 bg-magenta" />
				) : null}
				<span className="relative">
					<span
						className={`block w-6 h-6 absolute top-1/2 left-1/2 border ${_boc} ${
							_index === index
								? `opacity-100 visible scale-100`
								: `opacity-0 invisible scale-50`
						} z-20 transition-all delay-75 duration-300`}
						style={{
							transform: `translate3d(-50%, -50%, 0) rotateX(45deg) rotateZ(45deg)`,
						}}
					/>

					<span
						className={`block w-8 h-8 absolute top-1/2 left-1/2 border ${_boc} border-opacity-50 ${
							_index === index
								? `opacity-100 visible scale-100`
								: `opacity-0 invisible scale-50`
						} z-20 transition-all delay-150 duration-300`}
						style={{
							transform: `translate3d(-50%, -50%, 0) rotateX(45deg) rotateZ(45deg)`,
						}}
					/>

					<span
						className={`block w-10 h-10 absolute top-1/2 left-1/2 border ${_boc} border-opacity-10 ${
							_index === index
								? `opacity-100 visible scale-100`
								: `opacity-0 invisible scale-50`
						} z-20 transition-all delay-200 duration-300`}
						style={{
							transform: `translate3d(-50%, -50%, 0) rotateX(45deg) rotateZ(45deg)`,
						}}
					/>

					<span
						className={`pink-nav__gem block w-5 h-3 relative ${
							_index === index ? _bg : `bg-magenta`
						} z-40 ${clickable ? `cursor-pointer` : ``} appearance-none transition`}
						onClick={() => (clickable ? setIndex(_index) : false)}
					/>
				</span>
			</>
		)
	}

	return (
		<div className={`pink-nav flex items-center ${className} h-12`}>{_items}</div>
	)
}

export default PinkNav
