import { GatsbyImageProps } from 'gatsby-plugin-image'
import React from 'react'
import { BTN_COLOR } from '../Button'
import CharacterHeroBlade, {
	ICharacterHeroBladeProps,
} from '../CharacterHeroBlade'
import SimpleHeroBlade from '../SimpleHeroBlade'
import MediaBlade from '../MediaBlade'
import RosterCarouselBlade from '../RosterCarouselBlade'
import SignUpFormBlade from '../SignUpBlade'
import TextMediaBlade from '../TextMediaBlade'
import GameplayCarouselBlade from '../GameplayCarouselBlade'
import CharacterGridBlade from '../CharacterGridBlade'
import FullscreenCarouselBlade from '../FullscreenCarouselBlade'
import BossCarouselBlade from '../BossCarouselBlade'
import FaqsBlade from '../FaqsBlade'
import VideoBackgroundHeroBlade from '../VideoBackgroundHeroBlade'
import FullscreenLandingPage from '../FullScreenLandingPage'
import VideoBackgroundHeroWithSignupBlade from '../VideoBackgroundHeroWithSignupBlade'
import ArticlePageBlade from '../ArticlePageBlade'
import LoreSectionBlade from '../LoreSectionBlade'


export interface IButtonProps {
	__typename: string
	id: string
	text: string
	buttonIcon: {
		gatsbyImageData: GatsbyImageProps
		isImage: boolean
		format: string
		url: string
	}
	buttonColour: BTN_COLOR
	hoverColour: BTN_COLOR
	hasIcon: boolean
	externalLink: string
	isExternal: boolean
	link: string
}

export interface ICharacter {
	node: {
		name: string
		description: string
		thumbnail: any // TODO: typing
	}
}

interface IBladesProps {
	blades?: ICharacterHeroBladeProps[]
	allCharacters: ICharacter[]
}

enum BLADE_TYPES {
	CHARACTER_HERO_BLADE = `DatoCmsCharacterHeroBlade`,
	TEXT_MEDIA_BLADE = `DatoCmsTextMediaBlade`,
	MEDIA_BLADE = `DatoCmsMediaBlade`,
	SIGN_UP_FORM_BLADE = `DatoCmsSignUpFormBlade`,
	ROSTER_CAROUSEL_BLADE = `DatoCmsRosterCarouselBlade`,
	SIMPLE_HERO_BLADE = `DatoCmsSimpleHeroBlade`,
	GAMEPLAY_CAROUSEL_BLADE = `DatoCmsGameplayCarouselBlade`,
	CHARACTER_GRID_BLADE = `DatoCmsCharacterGridBlade`,
	FULLSCREEN_CAROUSEL_BLADE = `DatoCmsFullscreenCarouselBlade`,
	FAQS_BLADE = `DatoCmsFaqsBlade`,
	BOSS_CAROUSEL_BLADE = `DatoCmsBossCarouselBlade`,
	VIDEO_BACKGROUND_HERO_BLADE = `DatoCmsVideoBackgroundHeroBlade`,
	FULL_SCREEN_LANDING_PAGE_BLADE = `DatoCmsFullScreenLandingPageBlade`,
	VIDEO_BACKGROUND_HERO_WITH_SIGNUP_BLADE = `DatoCmsVideoBackgroundHeroWithSignupBlade`,
	ARTICLE_PAGE_BLADE = `DatoCmsArticlePageBlade`,
	LORE_SECTION_BLADE = `DatoCmsLoreSectionBlade`,
}

const getBladeType = ({
	key,
	type,
	props,
	allCharacters,
}: {
	key: number
	type: string
	props: any
	allCharacters: ICharacter[]
}) => {
	console.log(type)
	switch (type) {
		case BLADE_TYPES.CHARACTER_HERO_BLADE:
			return (
				<CharacterHeroBlade
					{...{ key, className: `lg:min-h-[100vh] lg:max-h-[100vh]`, ...props }}
				/>
			)
		case BLADE_TYPES.TEXT_MEDIA_BLADE:
			return (
				<TextMediaBlade
					{...{ key, className: `lg:min-h-[100vh] lg:max-h-[100vh]`, ...props }}
					isFirst={key === 0}
				/>
			)
		case BLADE_TYPES.MEDIA_BLADE:
			return (
				<MediaBlade
					{...{ key, className: `lg:min-h-[100vh] lg:max-h-[100vh]`, ...props }}
				/>
			)
		case BLADE_TYPES.SIGN_UP_FORM_BLADE:
			return (
				<SignUpFormBlade
					{...{ key, className: `lg:min-h-[100vh] lg:max-h-[100vh]`, ...props }}
				/>
			)
		case BLADE_TYPES.ROSTER_CAROUSEL_BLADE:
			return (
				<RosterCarouselBlade
					{...{ key, className: `lg:min-h-[100vh] lg:max-h-[100vh]`, ...props }}
				/>
			)
		case BLADE_TYPES.SIMPLE_HERO_BLADE:
			return (
				<SimpleHeroBlade
					{...{ key, className: `lg:min-h-[100vh] lg:max-h-[100vh]`, ...props }}
				/>
			)
		case BLADE_TYPES.GAMEPLAY_CAROUSEL_BLADE:
			return (
				<GameplayCarouselBlade
					{...{ key, className: `lg:min-h-[100vh] lg:max-h-[100vh]`, ...props }}
				/>
			)
		case BLADE_TYPES.CHARACTER_GRID_BLADE:
			return (
				<CharacterGridBlade
					{...{
						key,
						className: `lg:min-h-[100vh] lg:max-h-[100vh]`,
						allCharacters,
						...props,
					}}
				/>
			)
		case BLADE_TYPES.FULLSCREEN_CAROUSEL_BLADE:
			return (
				<FullscreenCarouselBlade
					{...{
						key,
						className: `lg:min-h-[100vh] lg:max-h-[100vh]`,
						...props,
					}}
				/>
			)
		case BLADE_TYPES.FAQS_BLADE:
			return (
				<FaqsBlade
					{...{
						key,
						className: `lg:min-h-[100vh] lg:max-h-[100vh]`,
						...props,
					}}
				/>
			)
		case BLADE_TYPES.BOSS_CAROUSEL_BLADE:
			return (
				<BossCarouselBlade
					{...{
						key,
						className: `lg:min-h-[100vh] lg:max-h-[100vh]`,
						...props,
					}}
				/>
			)
		case BLADE_TYPES.VIDEO_BACKGROUND_HERO_BLADE:
			return (
				<VideoBackgroundHeroBlade
					{...{
						key,
						className: `lg:min-h-[100vh] lg:max-h-[100vh]`,
						...props,
					}}
				/>
			)
		case BLADE_TYPES.FULL_SCREEN_LANDING_PAGE_BLADE:
			return (
				<FullscreenLandingPage
					{...{
						key,
						className: `lg:min-h-[100vh] lg:max-h-[100vh]`,
						...props,
					}}
				/>
			)
		case BLADE_TYPES.VIDEO_BACKGROUND_HERO_WITH_SIGNUP_BLADE:
			return (
				<VideoBackgroundHeroWithSignupBlade
					{...{
						key,
						className: `lg:min-h-[100vh]`,
						...props,
					}}
				/>
			)
		case BLADE_TYPES.ARTICLE_PAGE_BLADE:
			return (
				<ArticlePageBlade
					{...{
						key,
						className: `lg:min-h-[100vh]`,
						...props,
					}}
				/>
			)
		case BLADE_TYPES.LORE_SECTION_BLADE:
			return (
				<LoreSectionBlade
					{...{
						key,
						className: ``,
						...props,
					}}
				/>
			)
		default:
			// return console.log({ key, type, props })
			return false
	}
}

const Blades: React.FC<IBladesProps> = ({
	blades = [],
	allCharacters = [],
}) => {
	return (
		console.log({ blades }),
		(
			<>
				{blades.map(({ __typename: type, ...props }, i) =>
					getBladeType({ key: i, type, allCharacters, props })
				)}
			</>
		)
	)
}

export default Blades
